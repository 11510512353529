export function getAllObjectKeys(
  obj: object,
  acc: string[],
  maxRecursionDepth = 5
): string[] {
  if (maxRecursionDepth <= 0) {
    return acc;
  }
  const ret = acc.concat(Object.getOwnPropertyNames(obj));
  try {
    return getAllObjectKeys(
      Object.getPrototypeOf(obj),
      ret,
      maxRecursionDepth - 1
    );
  } catch (err: unknown) {
    return acc;
  }
}

export function stringifyEventError(e: Event): string {
  return JSON.stringify(e, getAllObjectKeys(e, []));
}
