import type { Event as ErrorEvent, EventHint } from '@sentry/browser';

import { EventError } from './EventError/EventError';
import { NonObjectError } from './NonObjectError/NonObjectError';

export function isErrorEvent(e: unknown): e is Error {
  return !!e && typeof e === 'object' && 'message' in e && 'stack' in e;
}

export function recomputeErrorAndEvent(
  event: ErrorEvent,
  hint: EventHint,
  isUnwrapperError = false
): { error: Error; event: ErrorEvent } {
  const error = hint.originalException;
  const isError = isErrorEvent(error);

  if (isUnwrapperError) {
    event.tags = event.tags || {};
    event.tags.errorType = 'unhandled-rejection';
  }

  if (isError) {
    return { error, event };
  }

  let finalError: Error;

  if (error instanceof Event) {
    finalError = new EventError(error);
  } else {
    finalError = new NonObjectError(error);
  }

  // overwrite default exception with the new Error data
  event.exception = {
    values: [
      {
        type: finalError.name,
        value: finalError.message,
      },
    ],
  };

  return { error: finalError, event };
}

export function isUnhandledRejectionEvent(
  e: unknown
): e is PromiseRejectionEvent {
  return e instanceof Event && e.type === 'unhandledrejection';
}
