import { WithContextError } from '../WithContextError';

import { stringifyEventError } from './EventError.helper';

export class EventError extends WithContextError {
  constructor(event: Event) {
    super(
      `Error received is an event`,
      'Event Values',
      JSON.parse(stringifyEventError(event))
    );
    this.name = 'EventError';
  }
}
