import { getAllObjectKeys } from '../EventError/EventError.helper';

export function stringifyNonObjectError(o: unknown): unknown {
  if (Array.isArray(o)) {
    return o.map(stringifyNonObjectError);
  }
  if (typeof o !== 'object' || o === null) {
    return o;
  }
  if (o instanceof Promise) {
    return Object.getPrototypeOf(o).toString();
  }
  const d = Object.assign(Object.create(Object.getPrototypeOf(o)), o);
  Object.getOwnPropertySymbols(o).forEach((k) => {
    d[`<${Symbol.keyFor(k)}>`] = o[k as keyof typeof o];
    delete d[k];
  });
  Object.keys(d).forEach((k) => {
    d[k] = stringifyNonObjectError(d[k]);
  });
  return JSON.stringify(d, getAllObjectKeys(d, []), 4);
}
