import { TaxCredentialsStatus } from '@legalplace/shared';

export const updateTaxCredentialsBanner = {
  [TaxCredentialsStatus.Expired]: {
    title: 'Vos identifiants impots.gouv ne sont plus à jour',
    message:
      'Pour que votre comptable puisse faire vos déclarations, vos identifiants doivent être à jour.',
    button_label: 'Renseigner mes identifiants',
  },
  [TaxCredentialsStatus.ExpiresSoon]: {
    title: 'Vos identifiants impots.gouv arrivent bientôt à expiration',
    message:
      'Pour que votre comptable puisse faire vos déclarations, vos identifiants doivent être à jour.',
    button_label: 'Renseigner mes identifiants',
  },
  [TaxCredentialsStatus.InvalidCredentials]: {
    title:
      'Les identifiants impots.gouv fournis à votre comptable sont invalides',
    message:
      'Pour que votre comptable puisse faire vos déclarations, vos identifiants doivent être à jour.',
    button_label: 'Renseigner mes identifiants',
  },
  [TaxCredentialsStatus.CredentialsChanged]: {
    title: 'Les identifiants impots.gouv ne sont plus à jour',
    message:
      'Pour que votre comptable puisse faire vos déclarations, vos identifiants doivent être à jour.',
    button_label: 'Renseigner mes identifiants',
  },

  [TaxCredentialsStatus.MissingSecurityCode]: {
    title: "Code d'activation impots.gouv manquant",
    message:
      "Veuillez saisir le code d'activation de votre compte reçu par courrier.",
    button_label: 'Saisir mon code',
  },

  [TaxCredentialsStatus.NotSubscribedToVat]: {
    title: 'Non adhésion à la TVA',
    message:
      "Votre création n'est pas terminée, vous devez maintenant adhérer aux services. Suivez le tutoriel en cliquant sur le bouton ci-dessous.",
    button_label: "J'adhère aux services",
  },

  [TaxCredentialsStatus.CredentialsNotMatchingCompany]: {
    title: "Identifiants ne concernent pas l'entreprise",
    message:
      'Les identifiants de connexion que vous nous avez transmis ne concernent pas votre entreprise.',
    button_label: 'Renseigner mes identifiants',
  },

  [TaxCredentialsStatus.UnInitializedCredentials]: {
    title: 'Création du compte non initié',
    message:
      "A la suite de la création de votre compte sur impots.gouv, vous devez l'activer en cliquant sur le lien reçu par mail. Suivez le tutoriel en cliquant sur le bouton ci-dessous.",
    button_label: 'Voir la méthode',
  },
  activation_code: {
    title: 'Saisissez votre code d’activation',
    banner_title:
      'Veuillez saisir le code d’activation des impôts reçu par courrier.',
    input_field_label: 'Code d’activation',
    encrypted_information_message: 'Vos informations sont chiffrées.',
    button_label: 'Valider le code d’activation',
  },
};
