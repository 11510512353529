import {
  AccountingStatus,
  BalanceType,
  BillingInvoiceStatus,
  BillingQuoteStatus,
  CategorizationSource,
  OperationsStatus,
  TransactionStatus,
  TransactionType,
} from '@legalplace/shared';

import type { IActiveFilters, IFilters } from '../interfaces';

export const DEFAULT_FILTER_TRANSACTION_TYPES = [
  TransactionType.Virement,
  TransactionType.VirementInstantane,
  TransactionType.Prelevement,
  TransactionType.Carte,
  TransactionType.CarteEnLigne,
  TransactionType.CarteMagasin,
  TransactionType.RetraitDistributeur,
  TransactionType.Unknown,
];

export const DEFAULT_FILTER_OPERATIONS_STATUS = [
  OperationsStatus.NOT_CATEGORIZED,
];

export const DEFAULT_FILTER_CATEGORIZATION_SOURCE = [
  CategorizationSource.AI,
  CategorizationSource.User,
  CategorizationSource.AccountingCollaborator,
  CategorizationSource.MassByUser,
  CategorizationSource.MassByAccountingCollaborator,
];

export const DIRECT_DEBIT_DEFAULT_FILTERS: IFilters = {
  transactionType: [TransactionType.Prelevement],
  transactionStatus: [
    TransactionStatus.Booked,
    TransactionStatus.Pending,
    TransactionStatus.Rejected,
  ],
  balanceType: [BalanceType.Debit],
};

export const DIRECT_DEBIT_ACTIVE_FILTERS: IActiveFilters = {
  search: true,
  date: true,
  transactionAmount: true,
};

export const CREDIT_TRANSFER_DEFAULT_FILTERS: IFilters = {
  transactionType: [
    TransactionType.Virement,
    TransactionType.VirementInstantane,
  ],
  transactionStatus: [
    TransactionStatus.Booked,
    TransactionStatus.Pending,
    TransactionStatus.Rejected,
  ],
  balanceType: [BalanceType.Debit],
};

export const CREDIT_TRANSFER_ACTIVE_FILTERS: IActiveFilters = {
  search: true,
  date: true,
  transactionAmount: true,
};

export const INVOICE_ACTIVE_FILTERS: IActiveFilters = {
  search: true,
  date: true,
  billingInvoiceStatuses: true,
};

export const INVOICE_DEFAULT_FILTERS: IFilters = {
  billingInvoiceStatuses: Object.values(BillingInvoiceStatus),
};

export const QUOTE_ACTIVE_FILTERS: IActiveFilters = {
  search: true,
  date: true,
  billingQuoteStatuses: true,
};

export const QUOTE_DEFAULT_FILTERS: IFilters = {
  billingQuoteStatuses: Object.values(BillingQuoteStatus),
};

export const TRANSACTIONS_DEFAULT_FILTERS: IFilters = {
  transactionStatus: [TransactionStatus.Booked],
  balanceType: [BalanceType.Credit, BalanceType.Debit],
  transactionType: DEFAULT_FILTER_TRANSACTION_TYPES,
  accounts: [],
  categorizationSource: DEFAULT_FILTER_CATEGORIZATION_SOURCE,
  operationsStatus: DEFAULT_FILTER_OPERATIONS_STATUS,
};

export const TRANSACTIONS_ACTIVE_FILTERS: IActiveFilters = {
  accounts: true,
  search: true,
  date: true,
  balanceType: true,
  transactionType: true,
  transactionAmount: true,
  categorizationSource: true,
  operationCategoryExternalIds: true,
  accountingStatus: true,
};

export const EXPENSES_ACTIVE_FILTERS: IActiveFilters = {
  search: true,
  date: true,
  transactionAmount: true,
  proofStatus: true,
  operationsStatus: true,
  accountingStatus: true,
  categorizationSource: true,
  operationCategoryExternalIds: true,
};

export const EXPENSES_DEFAULT_FILTERS: IFilters = {
  transactionType: [TransactionType.NoteDeFrais],
  transactionStatus: [TransactionStatus.Booked, TransactionStatus.Pending],
  balanceType: [BalanceType.Credit],
  categorizationSource: DEFAULT_FILTER_CATEGORIZATION_SOURCE,
  operationsStatus: DEFAULT_FILTER_OPERATIONS_STATUS,
};

export const PCA_HOME_ACTIVE_FILTERS: IActiveFilters = {
  search: true,
  date: true,
  balanceType: true,
  transactionType: true,
  transactionAmount: true,
};

export const PCA_HOME_DEFAULT_FILTERS: IFilters = {
  transactionStatus: [TransactionStatus.Booked],
  balanceType: [BalanceType.Credit, BalanceType.Debit],
  transactionType: Object.values(TransactionType).filter(
    (type) => type !== TransactionType.OD
  ),
  categorizationSource: DEFAULT_FILTER_CATEGORIZATION_SOURCE,
  operationsStatus: DEFAULT_FILTER_OPERATIONS_STATUS,
};

export const TRANSACTIONS_NOT_JUSTIFIED_FILTERS: IFilters = {
  transactionType: Object.values(TransactionType),
  balanceType: [BalanceType.Credit, BalanceType.Debit],
  transactionStatus: [TransactionStatus.Booked],
  accountingStatus: [AccountingStatus.NotJustified],
};

export const MANUAL_CATEGORIZATION_SOURCES = [
  CategorizationSource.AccountingCollaborator,
  CategorizationSource.MassByAccountingCollaborator,
  CategorizationSource.MassByUser,
  CategorizationSource.User,
];
