import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Capacitor } from '@capacitor/core';
import {
  SubscriptionFeatureStatus,
  WHITELISTED_COMPANIES,
} from '@legalplace/shared';
import type { IHeaderAppLogo, ILeftMenuButton } from '@legalplace/storybook';
import { BankHeaderV2, StreamLineIconList } from '@legalplace/storybook';

import { ampli } from '../../ampli/api';
import logo from '../../assets/images/logo.svg';
import { EMAIL_LOCAL_STORAGE_KEY, Status } from '../../constants';
import { PageVariant } from '../../constants/pageWrapper.constants';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { useCurrentDeviceBreakpoint } from '../../hooks/useCurrentDeviceBreakpoint';
import { postLogoutUser } from '../../services/api/auth.api';
import { getCompanyReferral } from '../../services/api/company.api';
import {
  getDeviceId,
  saveInLocalStorage,
} from '../../services/localStorage/localStorage';
import {
  buildMenuNavigationItem,
  buildNavigationItemList,
} from '../../services/router/navigationitemList';
import {
  selectAccountsAllIds,
  selectAccountStatus,
} from '../../store/accounts/selectors';
import { selectAccountCardStatus } from '../../store/cards/selectors';
import { setActiveCompanyId } from '../../store/companies/actions';
import {
  selectActiveCompany,
  selectCompaniesById,
} from '../../store/companies/selectors';
import { useGetFeatureFlagsQuery } from '../../store/featureFlags/reducer';
import { useGetSalesChannelsQuery } from '../../store/salesChannels/reducer';
import { startLogoutUser } from '../../store/user/actions';
import { selectUserInformations } from '../../store/user/selector';

import { AppBottomNav } from './AppBottomNav';
import { LeftMenu } from './LeftMenu';
import { ReferalModal } from './ReferralModal';

import {
  ConditionnalDisplayInMobileTabletOnly,
  HideInLargeDesktop,
  ShowLargeDesktopOnly,
} from '../common/layout/responsive.style';

export function NavigationWrapper({
  isProtectedRoute,
  pageVariant,
}: {
  isProtectedRoute?: boolean;
  pageVariant?: PageVariant;
}): JSX.Element | null {
  const { companyId } = useParams();
  const { t } = useTranslation('general', { keyPrefix: 'navigation' });
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isMobileOrTablet, isSmallDesktop } = useCurrentDeviceBreakpoint();

  const currentCompany = useAppSelector(selectActiveCompany);
  const { userId, firstName, lastName, email } = useAppSelector(
    selectUserInformations
  );
  const { data: featureFlags } = useGetFeatureFlagsQuery(currentCompany?.id, {
    skip: !currentCompany?.id,
  });

  const { data: salesChannels = [] } = useGetSalesChannelsQuery(
    currentCompany?.id ?? '',
    {
      skip: !currentCompany?.id,
    }
  );

  const fetchAccountsStatus = useAppSelector(selectAccountStatus);
  const companiesById = useAppSelector(selectCompaniesById);
  const cardStatus = useAppSelector(selectAccountCardStatus);
  const accountsAllIds = useAppSelector(selectAccountsAllIds);
  const hasNoAccounts =
    fetchAccountsStatus === Status.IDLE && !accountsAllIds?.length;

  const [headerAppLogo, setHeaderAppLogo] = useState<IHeaderAppLogo>({
    image: logo,
    redirectUri: '/',
  });
  const [referralLink, setReferralLink] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isOnboardingPage =
    pathname.includes('bienvenue') || pathname.includes('inactif');
  const isFromMenu = search.includes('from=menu');

  const shouldShowLeftMenu = pageVariant === PageVariant.DEFAULT;
  const shouldShowHeader = pageVariant !== PageVariant.NO_MENU;
  const shouldShowMenuBar = !isFromMenu && !isOnboardingPage;

  const isMobileApp = Capacitor.isNativePlatform();

  const menuItemList = Object.values(companiesById).map((company) => ({
    label: company.name,
    id: company.id,
    active: company.id === companyId,
  }));

  useEffect(() => {
    const getReferralLink = async (): Promise<void> => {
      if (!currentCompany?.id) {
        return;
      }
      try {
        const {
          data: { referralUrl },
        } = await getCompanyReferral(currentCompany.id);
        if (referralUrl) {
          setReferralLink(referralUrl);
        }
      } catch (error: unknown) {
        console.error('Error while fetching referral link', error);
      }
    };
    getReferralLink();
  }, [currentCompany?.id]);

  const computeActiveItemId = (): string => {
    const pathnameSegments = pathname.split('/');
    if (pathnameSegments.length <= 2) {
      return 'transaction';
    }
    return pathnameSegments[pathnameSegments.length - 1];
  };

  const navigationItemList = buildNavigationItemList({
    t,
    companyId: currentCompany?.id,
    featureMap: currentCompany?.featureMap,
    isDesktop: !isMobileOrTablet,
    isAccountingTrialEnded:
      currentCompany?.featureMap?.ComptaBase?.status ===
      SubscriptionFeatureStatus.TrialExpired,
    hasNoAccounts,
    shouldShowBankUpsell: featureFlags?.DISPLAY_BANKING_UPSELL_IN_MENU_FLAG,
    shouldShowAccountingUpsell:
      featureFlags?.DISPLAY_ACCOUNTING_UPSELL_IN_MENU_FLAG,
    shouldShowMyAccountantPage: currentCompany?.hasSupportProfile,
    shouldShowImmobilisationsPage: currentCompany?.hasImmobilisations,
    shouldShowSalesReportPage:
      WHITELISTED_COMPANIES.includes(currentCompany?.id || '') &&
      !!salesChannels.length,
  });

  const appBottomNavNavigationMenuItem = buildMenuNavigationItem(
    t,
    currentCompany?.id
  );

  const goToCompanyPage = (id: string): void => {
    dispatch(setActiveCompanyId(id));
    navigate(`/${id}/tableau-de-bord`);
  };

  const goToSettings = (): void => {
    if (!currentCompany) {
      return;
    }
    navigate(`/${currentCompany.id}/parametres?section=liste`);
  };

  const logoutUser = async (): Promise<void> => {
    if (isMobileApp && email) {
      await saveInLocalStorage(EMAIL_LOCAL_STORAGE_KEY, email);
    }
    const deviceId = await getDeviceId();
    await postLogoutUser(deviceId || undefined);
    dispatch(startLogoutUser());
    navigate(`/connexion`);
  };

  const navUserMenuWithoutSettingsButtonAndHelpButtonData = {
    buttonText: currentCompany?.name || '',
    menuItemList,
    selectMenuItemHandler: goToCompanyPage,
    userFullName: `${firstName} ${lastName}`,
    logoutHandler: logoutUser,
    helpHandler: undefined,
    settingsHandler: undefined,
    t,
    isNavMenuOpen: false,
    setIsNavMenuOpen: () => {},
  };

  const navUserMenuWithoutSettingsButtonData = {
    ...navUserMenuWithoutSettingsButtonAndHelpButtonData,
    helpHandler: () => ampli.supportIntercomOpened({ source: 'Menu' }),
  };

  const fullNavUserMenuData = {
    ...navUserMenuWithoutSettingsButtonData,
    settingsHandler: goToSettings,
  };

  const leftMenuButtons: ILeftMenuButton[] = [
    {
      id: 'settings',
      icon: StreamLineIconList.cog,
      onClickHandler: goToSettings,
    },
    {
      id: 'help',
      icon: StreamLineIconList.headphonescustomersupport,
      onClickHandler: () => ampli.supportIntercomOpened({ source: 'Menu' }),
    },
  ];

  useEffect(() => {
    if (companyId) {
      dispatch(setActiveCompanyId(companyId));
      setHeaderAppLogo((currentValue: IHeaderAppLogo) => ({
        ...currentValue,
        redirectUri: `/${companyId}/tableau-de-bord`,
      }));
      return;
    }

    setHeaderAppLogo((currentValue: IHeaderAppLogo) => ({
      ...currentValue,
      redirectUri: `/`,
    }));
  }, [companyId, currentCompany?.featureMap?.ComptaBase?.isActive, dispatch]);

  useEffect(() => {
    if (!currentCompany) {
      return;
    }
    window.dataLayer?.push({
      event: 'userData',
      user_id: userId,
      card_status: cardStatus,
      bank_activated: !!currentCompany.featureMap?.BankBase?.isActive,
      accounting_activated: !!currentCompany.featureMap?.ComptaBase?.isActive,
    });
    window.Intercom?.('update', {
      user_id: userId,
      'LPP card_status': cardStatus,
      'LPP bank_activated': !!currentCompany.featureMap?.BankBase?.isActive,
      'LPP accounting_activated':
        !!currentCompany.featureMap?.BankBase?.isActive,
    });
  }, [cardStatus, currentCompany, userId]);

  const handleReferralButtonClick = (): void => setIsModalOpen(true);
  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };

  return (
    <>
      {shouldShowHeader && (
        <ConditionnalDisplayInMobileTabletOnly
          alwaysDisplay={pageVariant === PageVariant.NAVBAR}
        >
          <BankHeaderV2
            headerAppLogo={headerAppLogo}
            isUserLoggedIn={isProtectedRoute ? !!userId : false}
            navUserMenuData={
              pageVariant === PageVariant.NAVBAR
                ? navUserMenuWithoutSettingsButtonData
                : fullNavUserMenuData
            }
            linkComponent={Link}
            hasBoxShadow={!(isMobileOrTablet || isSmallDesktop)}
          />
        </ConditionnalDisplayInMobileTabletOnly>
      )}
      {shouldShowLeftMenu && (
        <ShowLargeDesktopOnly>
          <LeftMenu
            headerData={headerAppLogo}
            items={navigationItemList}
            activeItemId={computeActiveItemId()}
            buttons={leftMenuButtons}
            navUserMenuData={navUserMenuWithoutSettingsButtonAndHelpButtonData}
            linkComponent={Link}
            footerActionLabel={referralLink ? t('referral_label') : undefined}
            footerActionIcon={
              referralLink ? StreamLineIconList.giftbox : undefined
            }
            onFooterActionClick={
              referralLink ? handleReferralButtonClick : undefined
            }
          />
        </ShowLargeDesktopOnly>
      )}
      {shouldShowMenuBar && (
        <HideInLargeDesktop>
          <AppBottomNav
            navigationItemList={navigationItemList}
            menuNavigationItem={appBottomNavNavigationMenuItem}
            linkComponent={Link}
            activeItemId={computeActiveItemId()}
          />
        </HideInLargeDesktop>
      )}
      {isModalOpen && (
        <ReferalModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          referralLink={referralLink}
        />
      )}
    </>
  );
}
