import { put, takeEvery } from 'typed-redux-saga';

import { resetAccounts } from '../../accounts/actions';
import { resetInvoice } from '../../billing/invoice/actions';
import { resetQuote } from '../../billing/quote/actions';
import { resetCards } from '../../cards/actions';
import { resetCategories } from '../../categories/actions';
import { resetCompanies } from '../../companies/actions';
import { resetOnboardingSteps } from '../../onboardingSteps/actions';
import { resetSalesReports } from '../../salesReports/actions';
import { resetTransactions } from '../../transactions/actions';
import { startLogoutUser } from '../actions';

function* handleStartLogoutUser(): Generator {
  // Others
  yield put(resetCompanies());
  yield put(resetTransactions());
  yield put(resetAccounts());
  // Bank
  yield put(resetCards());
  // Billing
  yield put(resetInvoice());
  yield put(resetQuote());
  // Sales Reports
  yield put(resetSalesReports());
  // TODO add clear of cache when logging out
  // Accounting
  yield put(resetCategories());
  // Onboarding
  yield put(resetOnboardingSteps());
}

function* startLogoutUserSaga(): Generator {
  yield takeEvery(startLogoutUser, handleStartLogoutUser);
}

export default startLogoutUserSaga;
