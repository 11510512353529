import {
  Button,
  flexBaseColumn,
  FlexColumnFullWidth,
  styled,
} from '@legalplace/storybook';

export const IbanSelectionForm = styled.form`
  ${flexBaseColumn};
  width: 100%;
  gap: ${({ theme }) => theme.spacing.medium};
  align-items: flex-start;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export const SubmitButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing.xsmall};
  align-self: flex-end;
  width: fit-content;
`;

export const PaymentDisclaimer = styled(FlexColumnFullWidth)`
  background-color: ${({ theme }) => theme.backgroundColors.primary50};
  border: 1px solid ${({ theme }) => theme.backgroundColors.primary200};
  border-radius: ${({ theme }) => theme.borders.radiusMd};
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;
