import { WithContextError } from '../WithContextError';

import { stringifyNonObjectError } from './NonObjectError.helper';

export class NonObjectError extends WithContextError {
  constructor(error: unknown) {
    super('Object received is not an error', 'Recieved Object', {
      recievedObject: stringifyNonObjectError(error),
    });
    this.name = 'NonObjectError';
  }
}
