import type { TFunction } from 'react-i18next';

import type { FindAccountDto } from '@legalplace/bankroot-api/modules/account/dto';
import type { UpdateRibInformationRequestDto } from '@legalplace/bankroot-api/modules/onboardingFlow/dto';

import type {
  IRibForm,
  IRibFormError,
  IRibFormState,
} from '../../../../../interfaces/forms/rib.interface';
import { validateIbanForAccountId } from '../../../../../services/api/account.api';

export const formatRibInformationDto = (
  companyId: string,
  taxRibValues: IRibForm,
  subscriptionRibValues: IRibForm
): UpdateRibInformationRequestDto => ({
  taxRibInformation: {
    accountId: taxRibValues.accountId,
    iban: taxRibValues.accountId ? undefined : taxRibValues.iban,
    bic: taxRibValues.bic,
  },
  subscriptionRibInformation: {
    companyId,
    accountId: subscriptionRibValues.accountId,
    iban: subscriptionRibValues.accountId
      ? undefined
      : subscriptionRibValues.iban,
  },
});

export const getAccountsWithFrenchIban = (
  accounts: FindAccountDto[]
): FindAccountDto[] =>
  accounts.filter((account) => account.iban?.startsWith('FR'));

export const getHasOnlyOneAccountWithFrenchIban = (
  accounts: FindAccountDto[]
): boolean => getAccountsWithFrenchIban(accounts).length === 1;

export const getHasOneValidAccountWithFrenchIban = async (
  accounts: FindAccountDto[]
): Promise<boolean> => {
  const frenchAccounts = accounts.filter((account) =>
    account.iban?.startsWith('FR')
  );

  return frenchAccounts.some(async (account) => {
    const {
      data: { valid },
    } = await validateIbanForAccountId(account.id);
    return valid;
  });
};

export const selectFirstAccountWithFrenchIban = (
  accounts: FindAccountDto[]
): string | undefined =>
  accounts.find((account) => account.iban?.startsWith('FR'))?.id || undefined;

export const validateForm = async (
  formState: IRibFormState,
  t: TFunction
): Promise<boolean> => {
  const {
    values: { accountId, iban, bic },
  } = formState;
  const errors: IRibFormError = {};

  if (!accountId && !iban && !bic) {
    errors.accountIdErrorMessage = t('error.empty_field');
  }

  if (accountId) {
    if (iban && !iban.startsWith('FR')) {
      errors.accountIdErrorMessage = t('error.dropdown_fr_iban_only');
    } else {
      const {
        data: { valid },
      } = await validateIbanForAccountId(accountId);
      if (!valid) {
        errors.accountIdErrorMessage = t('error.dropdown_invalid_account');
      }
    }
  }

  formState.setErrors(errors);
  return Object.values(errors).some((error) => !!error);
};
