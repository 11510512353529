import type { AxiosError, AxiosPromise } from 'axios';

import { TOKEN_EXPIRED } from '@legalplace/shared';

import { NO_CONNECTION_TOAST_DEBOUNCE_TIME } from '../../constants';
import { NO_REDIRECT_TO_LOGIN_URI } from '../../constants/redirectToLoginUri.constants';
import { router } from '../../router';
import { store } from '../../store';
import { startLogoutUser } from '../../store/user/actions';
import { showToastError } from '../notifications/toast';

import { api } from './api';

interface HttpErrorResponse {
  error: string;
  message: string;
  statusCode: number;
  oauthUrl?: string;
}

const redirectToLogin = (): void => {
  const { pathname, search } = window.location;
  const queryParams =
    pathname === '/'
      ? ''
      : new URLSearchParams({ redirectUrl: pathname + search });
  store.dispatch(startLogoutUser());
  router.navigate(`/connexion?${queryParams}`);
};

const onResponseError = async (
  error: AxiosError
): Promise<AxiosPromise | null> => {
  if (error.response?.status === 401) {
    const originalConfig = error.config;
    // Access Token was expired
    if ((error.response.data as HttpErrorResponse).message === TOKEN_EXPIRED) {
      try {
        await api.post(`/auth/refresh`);
        return await api.request(originalConfig ?? {});
      } catch (_error) {
        console.error(
          `[onResponseError] - Error trying to refresh token`,
          error
        );
        // There was an error while refreshing token
      }
    }
    const { href } = window.location;

    const shouldRedirectToLogin = NO_REDIRECT_TO_LOGIN_URI.every(
      (uri) => !href.includes(uri)
    );

    if (shouldRedirectToLogin) {
      redirectToLogin();
    }
  } else if (error.response?.status === 412) {
    await api.post(`/auth/logout`);
    redirectToLogin();
  } else if (
    error.response &&
    (error.response.status >= 500 || error.response.status === 0) &&
    navigator.onLine
  ) {
    showToastError('Une erreur est survenue, veuillez réessayer.');
  }
  return Promise.reject(error);
};
let lastNoConnectionToastTimestamp = 0;

export const setupApiInterceptors = (): void => {
  api.interceptors.response.use((response) => response, onResponseError);
  api.interceptors.request.use(
    (config) => {
      if (
        !navigator.onLine &&
        Date.now() - lastNoConnectionToastTimestamp >
          NO_CONNECTION_TOAST_DEBOUNCE_TIME
      ) {
        lastNoConnectionToastTimestamp = Date.now();
        showToastError(
          'Impossible de réaliser cette action, vérifiez votre connexion internet.'
        );
        return {
          ...config,
          signal: AbortSignal.abort('No internet connection'),
        };
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};
