import {
  Button,
  FlexColumnFullWidth,
  FlexContainerBasicColumn,
  styled,
} from '@legalplace/storybook';

export const RibSelectionContainer = styled(FlexContainerBasicColumn)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 100%;
    flex-grow: 1;
    padding: ${({ theme }) =>
      `0 ${theme.spacing.medium} ${theme.spacing.medium}`};
  }

  width: 75%;
  gap: ${({ theme }) => theme.spacing.medium};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
`;

export const SynchronizeButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing.xsmall};
  align-self: flex-end;
  width: fit-content;
`;

export const SynchronizeDisclaimer = styled(FlexColumnFullWidth)`
  background-color: ${({ theme }) => theme.backgroundColors.danger25};
  border: 1px solid ${({ theme }) => theme.backgroundColors.danger200};
  border-radius: ${({ theme }) => theme.borders.radiusMd};
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;
