export const parseCookies = (str: string): { [key: string]: string } => {
  try {
    return str
      .split(';')
      .map((v) => v.split('='))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      }, {} as { [key: string]: string });
  } catch (e) {
    return {};
  }
};
