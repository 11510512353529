export const addAccount = {
  title: 'Configuration de votre compte',
  content:
    'Cette interface vous permet de justifier toutes vos transactions bancaires et de déclarer des notes de frais. Vous communiquerez ainsi à notre cabinet comptable toutes les informations nécessaires à sa prestation.<br /> <br /> Pour commencer, vous devez mettre en place la synchronisation avec les comptes bancaires de votre entreprise :',
  add_btn_text: 'Ajouter un compte',
  modal: {
    title: 'Ajouter un compte',
    primary_content:
      'Connectez votre compte(s) bancaire(s) professionnel(s) pour une catégorisation et une comptabilisation automatique grâce à notre intelligence artificielle.',
    secondary_content:
      'Vos données sont sécurisées sur des serveurs en Union européenne, garantissant une confidentialité absolue. Votre comptable aura accès aux détails nécessaires sans possibilité de virements.',
    bad_item_sync_message:
      'Une erreur est survenue lors de la synchronisation de vos comptes bancaires.',
  },
};
