import { call, put, takeEvery } from 'typed-redux-saga';

import { Capacitor } from '@capacitor/core';

import { getCompaniesRequest } from '../../../services/api/company.api';
import { initApp } from '../../../services/capacitor/appSetup';
import {
  fetchCompaniesFailed,
  fetchCompaniesSuccess,
  silenceFetchCompanies,
  startFetchCompanies,
} from '../actions';

function* handleFetchCompanies(): Generator {
  try {
    const response = yield* call(getCompaniesRequest);
    const { data: companies } = response;
    yield put(fetchCompaniesSuccess(companies));

    if (Capacitor.isNativePlatform()) {
      yield call(() => initApp());
    }
  } catch (error: unknown) {
    console.error(
      `[handleFetchCompanies] - Error trying to fetch companies`,
      error
    );
    yield put(fetchCompaniesFailed());
  }
}

function* fetchCompaniesSaga(): Generator {
  yield takeEvery(
    [startFetchCompanies, silenceFetchCompanies],
    handleFetchCompanies
  );
}

export default fetchCompaniesSaga;
