import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SubscriptionFeatureStatus } from '@legalplace/shared';
import { TooltipPositions, TooltipV2, Typography } from '@legalplace/storybook';

import { MENU_URI_SUFFIX } from '../../../../constants';
import { INTERCOM_START_URL } from '../../../../constants/academy.constants';
import { useAppSelector } from '../../../../hooks/store';
import { selectActiveCompany } from '../../../../store/companies/selectors';
import { selectOnboardingSteps } from '../../../../store/onboardingSteps/selector';

import {
  AcademyModalLearnMoreContainer,
  ArticlesButton,
  ButtonContainer,
  MyAccountantButton,
} from './AcademyModalLearnMore.style';

interface IAcademyModalLearnMoreProps {
  isDesktop?: boolean;
  onClose: () => void;
}

export function AcademyModalLearnMore({
  isDesktop,
  onClose,
}: IAcademyModalLearnMoreProps): JSX.Element {
  const { t } = useTranslation('academy', {
    keyPrefix: 'modal.learn_more',
  });
  const navigate = useNavigate();
  const activeCompany = useAppSelector(selectActiveCompany);
  const onboardingStep = useAppSelector(selectOnboardingSteps);
  const isMyAccountantButtonDisabled =
    activeCompany?.featureMap?.ComptaBase?.status ===
      SubscriptionFeatureStatus.InTrial &&
    !onboardingStep.accountingOnboardingInformation?.isContractSigned;

  return (
    <AcademyModalLearnMoreContainer>
      <Typography tag="p" fontWeight="500" color="primary900">
        {t('title')}
      </Typography>
      <Typography tag="p5" fontWeight="400" color="grey600">
        {t('description')}
      </Typography>
      <ButtonContainer>
        <ArticlesButton
          forwardedAs="a"
          target="_blank"
          rel="noopener noreferrer"
          href={INTERCOM_START_URL}
        >
          {t('articles_button')}
        </ArticlesButton>
        {activeCompany?.hasSupportProfile &&
          (isMyAccountantButtonDisabled ? (
            <TooltipV2
              position={
                isDesktop ? TooltipPositions.RIGHT : TooltipPositions.TOP
              }
              bgColor="primary900"
              id="academy_learn_more_tooltip"
              content={t('tooltip')}
              tooltipWidth={{ default: '25.6rem' }}
            >
              <MyAccountantButton disabled>
                {t('my_accountant_button')}
              </MyAccountantButton>
            </TooltipV2>
          ) : (
            <MyAccountantButton
              onClick={() => {
                navigate(
                  `/${activeCompany?.id}/mon-comptable${
                    isDesktop ? '' : MENU_URI_SUFFIX
                  }`
                );
                onClose();
              }}
            >
              {t('my_accountant_button')}
            </MyAccountantButton>
          ))}
      </ButtonContainer>
    </AcademyModalLearnMoreContainer>
  );
}
