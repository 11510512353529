import { type ReactNode, useMemo } from 'react';

import {
  ErrorMessage,
  FeatherIcon,
  FeatherIconList,
  FormInputContainer,
  FormInputLabel,
  FormInputLabelContainer,
  FormInputLabelSubContainer,
  TooltipPositions,
  TooltipV2,
} from '@legalplace/storybook';

export function InputWrapper({
  label,
  children,
  isHalfWidth,
  className,
  name,
  hasError,
  errorMessage,
  tooltipMessage,
}: {
  label?: string;
  children: ReactNode[] | ReactNode;
  isHalfWidth?: boolean;
  className?: string;
  name: string;
  hasError?: boolean;
  errorMessage?: string;
  tooltipMessage?: string;
}): JSX.Element {
  const memoizedTooltip = useMemo(
    () => (
      <TooltipV2
        position={TooltipPositions.TOP}
        id={`${name}-tooltip`}
        bgColor="primary900"
        content={tooltipMessage}
        tooltipWidth={{ default: '28.8rem' }}
      >
        <FeatherIcon
          icon={FeatherIconList.helpCircle}
          spacing={{ default: '0 0.6rem' }}
          width="1.7rem"
          height="1.7rem"
          fillColor="grey400"
        />
      </TooltipV2>
    ),
    [name, tooltipMessage]
  );

  return (
    <FormInputContainer isHalfWidth={isHalfWidth} className={className}>
      {label && (
        <FormInputLabelContainer>
          <FormInputLabelSubContainer>
            <FormInputLabel htmlFor={name}>
              <span>{label} </span>
            </FormInputLabel>
            {!!tooltipMessage && memoizedTooltip}
          </FormInputLabelSubContainer>
        </FormInputLabelContainer>
      )}
      {children}
      {hasError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </FormInputContainer>
  );
}
