export const general = {
  navigation: {
    navigation_menu_connected_as: 'Connecté en tant que',
    logout_button: 'Déconnexion',
    help_button: 'Aide',
    settings_button: 'Paramètres',
    help_button_label: 'Aide',
    menu_button_label: 'Menu',
    dashboard: 'Tableau de bord',
    my_accountant: 'Mon comptable',
    transactions: 'Transactions',
    expenses: 'Notes de frais',
    deadlines: 'Échéances',
    invoices: 'Factures',
    cards: 'Cartes',
    credit_transfers: 'Virements',
    direct_debits: 'Prélèvements',
    accounting: 'Comptabilité',
    accounting_group_label: 'Comptabilité',
    bank_group_label: 'Compte pro',
    referral_label: 'Parrainez vos proches',
    invoice_group_label: 'Facturation',
    client: 'Clients',
    prestation: 'Prestations',
    quote: 'Devis',
    upsell_compta: 'Compte pro',
    upsell_compta_tag: 'Découvrir',
    accounting_to_bank_upsell: 'Compte pro',
    accounting_to_bank_upsell_tag: 'Gratuit',
    banking_to_accounting_upsell: 'Comptabilité',
    banking_to_accounting_upsell_tag: 'Essayer',
    immobilisations: 'Immobilisations',
    sales_report: 'Déclaration des ventes',
  },
  referral_modal: {
    title: 'Parrainez vos proches',
    subtitle: 'Gagnez 100 €, offrez 100 € !',
    paragraph:
      'Partagez ce lien à vos proches et recevez 100 € lors d’une création de société ou d’un abonnement d’expertise comptable.',
    copy_link_button: 'Copier le lien',
    copied_link_button: 'Lien copié !',
  },
  accounting_trial_end_modal: {
    title: 'Fin de l’offre comptabilité',
    cancel_registration_button_label: 'Abandonner',
    finalize_registration_button_label: 'Finaliser mon inscription',
    message_line_1:
      'Vous êtes sur le point d’arrêter votre inscription à notre offre comptabilité',
    message_line_2:
      'Si vous abandonnez votre inscription vous n’aurez plus accès aux fonctionnalités de comptabilité de LegalPlace Pro et nous ne ferons pas vos déclarations légales.',
    message_line_3: 'Souhaitez-vous reprendre votre inscription ?',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
  },
  file_preview: {
    download_started: 'Téléchargement démarré !',
    download_button: 'Télécharger',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    invoice_redirection_button: 'Accéder à ma facture',
  },
  confirm_accounting_onboarding: {
    page_title: 'Comptabilité - Confirmation de votre inscription',
    expired_reset_link: 'Ce lien a expiré ou est invalide.',
  },
};
