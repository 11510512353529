import {
  Button,
  css,
  flexBaseAlignCenter,
  flexBaseColumn,
  keyframes,
  styled,
  textEllipsis,
  Typography,
} from '@legalplace/storybook';

import { CollapsableSectionType } from '../../constants';

const CollapsableFormSectionContainerStyle = css`
  border-radius: ${({ theme }) => theme.borders.radiusMd};
  background-color: ${({ theme }) => theme.backgroundColors.grey25};
  border: ${({ theme }) =>
    `${theme.borders.widthRegular} ${theme.borders.styleRegular} ${theme.backgroundColors.grey125}`};
  padding: ${({ theme }) =>
    `${theme.spacing.xxxxsmall} ${theme.spacing.xsmall}`};
`;

export const CollapsableSectionContainer = styled.div<{
  type: CollapsableSectionType;
}>`
  ${flexBaseColumn}
  gap: ${({ theme }) => theme.spacing.xxxxxsmall};

  ${({ type }) =>
    type === CollapsableSectionType.FORM &&
    CollapsableFormSectionContainerStyle};
`;

export const SectionHeader = styled(Button)<{
  sectionType: CollapsableSectionType;
  isCollapsed: boolean;
  $isActive?: boolean;
}>`
  justify-content: space-between;

  padding: ${({ theme, sectionType }) =>
    sectionType === CollapsableSectionType.FORM
      ? `${theme.spacing.xxxxsmall} 0 ${theme.spacing.xxxxsmall} ${theme.spacing.xxxxsmall}`
      : `${theme.spacing.xxxxsmall} ${theme.spacing.small} ${theme.spacing.xxsmall} ${theme.spacing.xxsmall}`};

  height: 4rem;

  ${({ $isActive, theme, sectionType }) =>
    $isActive &&
    sectionType === CollapsableSectionType.DEFAULT &&
    `background-color: ${theme.backgroundColors.primary50};
    border-radius: ${theme.borders.radiusSm};
    :hover {
      background-color: ${theme.backgroundColors.primary100};
    }`};

  ${({ theme, sectionType, $isActive }) =>
    sectionType === CollapsableSectionType.DEFAULT &&
    !$isActive &&
    `border-radius: ${theme.borders.radiusSm};
    :hover {
      background-color: ${theme.backgroundColors.grey50};
    }`};
`;

export const TitleContainer = styled.div<{
  sectionType: CollapsableSectionType;
}>`
  ${flexBaseAlignCenter}
  gap: ${({ theme }) => theme.spacing.xxsmall};

  ${({ sectionType }) =>
    sectionType === CollapsableSectionType.FORM &&
    css`
      width: 50%;
      text-align: left;

      @media screen and (min-width: ${(props) =>
          props.theme.breakpoints.smallMedium}) {
        width: 60%;
      }
    `}
`;

export const CollapsableBodyContainer = styled.div`
  gap: ${({ theme }) => theme.spacing.xxxxxsmall};
`;

export const StyledText = styled(Typography)<{ noShrink?: boolean }>`
  ${textEllipsis};
  ${({ noShrink }) => noShrink && `flex-shrink: 0`};
`;

// Keep overflow hidden until the opening transition is over
const collapsableOpen = keyframes`
  0% {
    overflow: hidden;
  }
  100% {
    overflow: hidden;
  }
`;

export const CollapsableContainer = styled.div<{
  isCollapsed?: boolean;
  maxHeight: number;
}>`
  max-height: ${({ isCollapsed, maxHeight }) =>
    isCollapsed ? '0' : `${maxHeight}px`};
  overflow: ${({ isCollapsed }) => (isCollapsed ? 'hidden' : 'visible')};
  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      animation: 0.2s ${collapsableOpen};
    `};
  transition: max-height 0.2s ease-out;
`;
