import type { AxiosResponse } from 'axios';

import type { SubscriptionPricesByPlanAndPeriodicity } from '@legalplace/bankroot-api/modules/accountingSubscriptionPricing/accountingSubscriptionPricing.type';
import type { OnboardingStepsDto } from '@legalplace/bankroot-api/modules/onboarding/dto';
import type {
  GetContractUrlDto,
  SelectAccountingPlanRequestDto,
  UpdateRibInformationRequestDto,
} from '@legalplace/bankroot-api/modules/onboardingFlow/dto';
import type { UpdateTaxesCredentialsDto } from '@legalplace/bankroot-api/modules/taxesCredentials/dto/updateTaxesCredentials.dto';

import { api } from './api';

export const getOnboardingStepsRequest = (
  companyId: string
): Promise<AxiosResponse<OnboardingStepsDto>> =>
  api.get(`/onboarding-flow/onboarding-steps?companyId=${companyId}`);

export const getContractUrlRequest = (
  companyId: string
): Promise<AxiosResponse<GetContractUrlDto>> =>
  api.get(`/onboarding-flow/${companyId}/accounting-contract/url`);

export const updateTaxesCredentialsRequest = (
  companyId: string,
  updateTaxesCredentialsDto: UpdateTaxesCredentialsDto
): Promise<AxiosResponse<void>> =>
  api.post(
    `/onboarding-flow/${companyId}/taxes-credentials`,
    updateTaxesCredentialsDto
  );

export const uploadRibRequest = (
  companyId: string,
  fileUpload: File
): Promise<void> => {
  const formData = new FormData();
  formData.append('file', fileUpload);

  return api.post(`/onboarding-flow/${companyId}/pro-rib`, formData, {
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
  });
};

export const sendRibInformationsRequest = (
  companyId: string,
  updateRibInformationDto: UpdateRibInformationRequestDto
): Promise<AxiosResponse<void>> =>
  api.post(
    `/onboarding-flow/${companyId}/update-rib-information`,
    updateRibInformationDto
  );

export const skipCardOrderRequest = (): Promise<AxiosResponse<void>> =>
  api.post(`/onboarding-flow/skip-card-order`);

export const getSubscriptionPricesRequest = (
  companyId: string
): Promise<AxiosResponse<SubscriptionPricesByPlanAndPeriodicity>> =>
  api.get(`/onboarding-flow/${companyId}/pricing`);

export const selectAccountingPlanRequest = (
  companyId: string,
  { plan, periodicity }: SelectAccountingPlanRequestDto
): Promise<AxiosResponse<void>> =>
  api.post(`/onboarding-flow/${companyId}/select-plan`, { plan, periodicity });

export const generateContractUrlRequest = (
  companyId: string
): Promise<AxiosResponse<GetContractUrlDto>> =>
  api.post(`/onboarding-flow/${companyId}/accounting-contract/generate`);
