import { useEffect, useState } from 'react';

import { BankIconLoader } from '@legalplace/storybook';

import { useAppSelector } from '../../../../../hooks/store';
import { validateIbanForAccountId } from '../../../../../services/api/account.api';
import {
  selectAccountsById,
  selectAreAccountsLoading,
} from '../../../../../store/accounts/selectors';

import { AccountSelection } from './AccountSelection';
import { getAccountsWithFrenchIban } from './AccountSelection.helper';
import { NoAccount } from './NoAccount';

interface IRibSelectionProps {
  notifySuccess: () => void;
}

export function RibSelection({
  notifySuccess,
}: IRibSelectionProps): JSX.Element {
  const areAccountsLoading = useAppSelector(selectAreAccountsLoading);
  const accountsById = useAppSelector(selectAccountsById);
  const [someValidAccountId, setSomeValidAccountId] = useState('');
  const [hasValidateAccount, setHasValidateAccount] = useState(false);

  useEffect(() => {
    if (areAccountsLoading) {
      return;
    }

    const getHasSomeValidAccountWithFrenchIban = async (): Promise<void> => {
      const frenchAccounts = getAccountsWithFrenchIban(
        Object.values(accountsById)
      );

      try {
        // eslint-disable-next-line no-restricted-syntax
        for await (const account of frenchAccounts) {
          const {
            data: { valid },
          } = await validateIbanForAccountId(account.id);
          if (valid) {
            setSomeValidAccountId(account.id);
            return;
          }
        }
      } catch (error: unknown) {
        console.error(`[RibSelection] - Error validating accounts`, error);
      } finally {
        setHasValidateAccount(true);
      }
    };

    getHasSomeValidAccountWithFrenchIban();
  }, [accountsById, areAccountsLoading]);

  if (areAccountsLoading || !hasValidateAccount) {
    return <BankIconLoader />;
  }

  if (!someValidAccountId) {
    return <NoAccount />;
  }

  return (
    <AccountSelection
      notifySuccess={notifySuccess}
      defaultAccountId={someValidAccountId}
    />
  );
}
