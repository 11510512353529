import { call, put, select, takeEvery } from 'typed-redux-saga';

import type { PayloadAction } from '@reduxjs/toolkit';

import { getSalesMonthReportsRequest } from '../../../services/api/salesReports.api';
import { selectActiveCompanyId } from '../../companies/selectors';
import {
  failSalesMonthReportsRequest,
  fetchSalesMonthReportsSuccess,
  startFetchSalesMonthReportsRequest,
} from '../actions';

function* handleFetchSalesMonthReports(
  action: PayloadAction<{ startDate: string; endDate: string }>
): Generator {
  let companyId = '';
  try {
    companyId = (yield select(selectActiveCompanyId)) as string;

    const { startDate, endDate } = action.payload;

    const response = yield* call(getSalesMonthReportsRequest, companyId, {
      startDate,
      endDate,
    });
    const { data: monthReports } = response;
    yield put(fetchSalesMonthReportsSuccess(monthReports));
  } catch (error: unknown) {
    console.error(
      `[handleFetchQuote] - Error trying to fetch sales_reports for company : ${companyId}`,
      error
    );
    yield put(failSalesMonthReportsRequest());
  }
}

function* fetchSalesMonthReportsSaga(): Generator {
  yield takeEvery(
    startFetchSalesMonthReportsRequest,
    handleFetchSalesMonthReports
  );
}

export default fetchSalesMonthReportsSaga;
