import { call, put, select, takeEvery } from 'typed-redux-saga';

import type { PayloadAction } from '@reduxjs/toolkit';

import { getActiveFiltersFromQueryParams } from '../../../../components/transactionDashboard/TransactionDashboard.helper';
import { INVOICE_ACTIVE_FILTERS } from '../../../../constants';
import { getInvoicesRequest } from '../../../../services/api/billing/invoices.api';
import { selectActiveCompanyId } from '../../../companies/selectors';
import type { PaginationOptions } from '../../../transactions/types';
import {
  failInvoiceRequest,
  fetchInvoiceSuccess,
  startFetchInvoiceRequest,
} from '../actions';

function* handleFetchInvoice(
  action: PayloadAction<Partial<PaginationOptions>>
): Generator {
  let companyId = '';
  try {
    companyId = (yield select(selectActiveCompanyId)) as string;
    const filters = getActiveFiltersFromQueryParams(
      INVOICE_ACTIVE_FILTERS,
      new URLSearchParams(window.location.search)
    );
    const { page = 1, take = 10 } = action.payload;

    const response = yield* call(getInvoicesRequest, companyId, filters, {
      page,
      take,
    });
    const { data: invoice } = response;
    yield put(fetchInvoiceSuccess(invoice));
  } catch (error: unknown) {
    console.error(
      `[handleFetchInvoice] - Error trying to fetch invoice for company : ${companyId}`,
      error
    );
    yield put(failInvoiceRequest());
  }
}

function* fetchInvoiceSaga(): Generator {
  yield takeEvery(startFetchInvoiceRequest, handleFetchInvoice);
}

export default fetchInvoiceSaga;
