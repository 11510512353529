export default {
  code_sent: 'Un code vient d’être envoyé à l’adresse',
  code_duration: 'Ce code restera actif {{durationInMinutes}} minutes',
  resend_email_disabled: 'Renvoyer l’email {{timeout}}s',
  resend_email: 'Renvoyer l’email',
  wrong_code: 'Code faux, {{remainingAttempts}} essais restants',
  unknown_error: 'Une erreur est survenue, veuillez réessayer.',
  trust_modal: {
    modal_title: 'Faire confiance à cet appareil',
    content:
      'Cliquez sur "Faire confiance" pour ne plus avoir à confirmer les connexions sur cet appareil.',
    trust: 'Faire confiance',
    do_not_trust: 'Ignorer',
  },
  ignore_next_time:
    'Ignorer la validation à deux étapes sur cet appareil la prochaine fois.',
  disconnected_too_many_attempts:
    'Vous avez été déconnecté suite à plusieurs erreurs d’authentification.',
};
