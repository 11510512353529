import { Navigate, useSearchParams } from 'react-router-dom';

import { BankIconLoader } from '@legalplace/storybook';

import { useAppSelector } from '../../hooks/store';
import { selectCompaniesById } from '../../store/companies/selectors';

export function AccountingUpsellRedirection(): JSX.Element {
  const [searchParams] = useSearchParams();
  const companiesById = useAppSelector(selectCompaniesById);
  const paramSiren = searchParams.get('siren');
  const paramUtmSource = searchParams.get('utm_source');

  if (!paramSiren) {
    return <Navigate to="/" replace />;
  }

  if (Object.keys(companiesById).length === 0) {
    return <BankIconLoader />;
  }

  const company = Object.values(companiesById).find(
    ({ siren }) => siren === paramSiren
  );

  if (!company || company.featureMap.ComptaBase) {
    return <Navigate to="/" replace />;
  }

  return (
    <Navigate
      to={`/${company.id}/decouvrir-comptabilite?${
        paramUtmSource ? `utm_source=${paramUtmSource}` : ''
      }`}
      replace
    />
  );
}
