export default {
  title: 'Entrez votre adresse email',
  subtitle: 'Pour commencer votre expérience LegalPlace Pro.',
  email_field: 'Email',
  email_placeholder: 'nomprenom@email.fr',
  next_button_label: 'Continuer avec cet email',
  mobile_header_title: 'Création de compte',
  back_button_label: 'Retour',
  invalid_email_error: 'Cette adresse email est invalide.',
  appointment_screen: {
    mobile_header_title: 'Création de compte',
    title:
      'Pour terminer la création de votre compte, notre équipe vous contactera.',
    subtitle:
      'Vous pouvez aussi programmer ce rendez-vous si vous le souhaitez.',
    button: 'Prendre rendez-vous',
  },
};
