import {
  BANK_SYNCHRONIZATION_SOURCE,
  BROADCAST_CHANNEL_NAME,
  ConsentStatus,
  SWAN_CONSENT_ID,
} from '../../constants';
import type { IPopup } from '../../interfaces/consentPopup.interface';
import type { ConsentMessage } from '../../pages/consent.types';
import {
  ConsentAction,
  WINDOW_CLOSED_MESSAGE,
  WINDOW_LOADED_MESSAGE,
} from '../../pages/consent.types';
import { delay, popupWindow } from '../utils';

const apiEndpoint = import.meta.env.VITE_APP_API_URL;

export enum PopupType {
  Swan = 'swan',
  Bridge = 'bridge',
}

export type WindowSize = {
  width: number;
  height: number;
};

class WebPopupManager implements IPopup {
  private targetWindow: Window | null;

  private isTargetWindowLoaded = false;

  private isPrematurelyClosed = false;

  private readonly BROADCAST_CHANNEL_POPUP_NAME = BROADCAST_CHANNEL_NAME;

  private broadcastChannel: BroadcastChannel | undefined;

  private eventSource: EventSource | null;

  private targetWindowParameters?: {
    url: string;
    successCallback: () => Promise<void>;
    errorCallback: () => Promise<void>;
    popupType: PopupType;
  };

  constructor() {
    this.broadcastChannel = window.BroadcastChannel
      ? new BroadcastChannel(this.BROADCAST_CHANNEL_POPUP_NAME)
      : undefined;
  }

  // Start a timer when the window is opened. This timer is used to ensure
  // the popup is not forced to close right after being opened.
  // e.g: if .close() is called 1s after the window was opened it will close instantly
  // but it will close 700ms after being called if the window was opened 300ms ago.
  windowCloseDelay: Promise<void>;

  openPopup(size?: WindowSize): void {
    const { width, height } = size ?? { width: 620, height: 600 };
    this.isPrematurelyClosed = false;
    if (this.targetWindow) {
      this.targetWindow.close();
    }
    this.targetWindowParameters = undefined;
    this.isTargetWindowLoaded = false;
    this.targetWindow = popupWindow('/popup', 'Popup', width, height);

    if (!this.targetWindow) {
      throw new Error('Could not manage to open window');
    }

    this.windowCloseDelay = delay(1000);

    (this.broadcastChannel || window).addEventListener(
      'message',
      this.messageHandler as EventListenerOrEventListenerObject
    );
  }

  get isPopupOpen(): boolean {
    return !!this.targetWindow;
  }

  private forceClose(): void {
    this.targetWindow?.close();
    this.targetWindow?.removeEventListener('message', this.messageHandler);
    this.targetWindow = null;
    this.eventSource?.close();
    this.eventSource = null;
    window.onbeforeunload = null;
  }

  async close(): Promise<void> {
    return this.windowCloseDelay.then(() => {
      this.forceClose();
    });
  }

  private messageHandler = (event: MessageEvent): void => {
    if (event.data === WINDOW_CLOSED_MESSAGE) {
      this.isPrematurelyClosed = true;
    }

    if (event.data === WINDOW_LOADED_MESSAGE) {
      this.isTargetWindowLoaded = true;
      if (this.targetWindowParameters) {
        const { url, successCallback, errorCallback, popupType } =
          this.targetWindowParameters;
        this.redirectAndListenForCallback(
          url,
          successCallback,
          errorCallback,
          popupType
        );
      }
    }
  };

  getConsentStatusRequest = (
    consentId: string,
    successCallback: () => Promise<void>,
    errorCallback: () => Promise<void>
  ): EventSource => {
    if (this.eventSource) {
      this.eventSource.close();
    }

    const eventSource = new EventSource(`${apiEndpoint}/consent/${consentId}`);

    eventSource.onmessage = async (payload: MessageEvent<string>) => {
      eventSource.close();
      try {
        const { status: consentStatus } = JSON.parse(payload.data);

        if (consentStatus === ConsentStatus.accepted) {
          await successCallback();
        } else {
          await errorCallback();
        }
      } catch (error: unknown) {
        console.error(
          `[getConsentStatusRequest] - Error trying to get consent status for consent : ${consentId}`,
          error
        );
        await errorCallback();
      }
    };

    return eventSource;
  };

  redirectAndListenForCallback(
    url: string,
    successCallback: () => Promise<void>,
    errorCallback: () => Promise<void>,
    popupType: PopupType = PopupType.Swan
  ): void {
    const { VITE_APP_URL } = import.meta.env;
    if (!this.targetWindow || !VITE_APP_URL) {
      return;
    }
    if (this.isPrematurelyClosed) {
      throw new Error('Attempted to redirect to consent on closed popup');
    }
    if (!this.isTargetWindowLoaded) {
      this.targetWindowParameters = {
        url,
        successCallback,
        errorCallback,
        popupType,
      };
      return;
    }

    (this.broadcastChannel || this.targetWindow)?.postMessage(
      {
        action: ConsentAction.Redirect,
        payload: url,
      } as ConsentMessage, // Check that the popup location is in our domain so we don't send the consent message to some other website.
      VITE_APP_URL
    );

    let hasResolved = false;

    const callback = (success: boolean): Promise<void> => {
      // Ignore further calls once it has resolved
      if (hasResolved) {
        return Promise.resolve();
      }
      hasResolved = true;
      if (success) {
        return successCallback();
      }
      return errorCallback();
    };

    if (popupType === PopupType.Swan) {
      this.listenForConsentEnd(url, callback);
    } else {
      this.listenForBridge(callback);
    }
  }

  private listenForConsentEnd = (
    url: string,
    callback: (success: boolean) => Promise<void>
  ): void => {
    const consentId = new URLSearchParams(new URL(url).search).get(
      SWAN_CONSENT_ID
    );

    if (!consentId) {
      throw new Error('No consentId in url');
    }
    this.eventSource = this.getConsentStatusRequest(
      consentId,
      () => callback(true),
      () => callback(false)
    );
  };

  private listenForBridge = (
    callback: (success: boolean) => Promise<void>
  ): void => {
    const interval = setInterval(() => {
      if (this.targetWindow?.closed) {
        clearInterval(interval);
        callback(false);
      }
    }, 1_000);

    this.broadcastChannel?.addEventListener(
      'message',
      ({ data: { source, success } }) => {
        if (source !== BANK_SYNCHRONIZATION_SOURCE) {
          return;
        }
        clearInterval(interval);
        callback(success);
        this.forceClose();
      }
    );
  };
}

export const WebPopup = new WebPopupManager();
