import type {
  AxiosInterceptorManager,
  AxiosInterceptorOptions,
  AxiosResponse,
} from 'axios';

type AxiosRequestInterceptorUse<T> = (
  onFulfilled?: ((value: T) => T | Promise<T>) | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRejected?: ((error: any) => any) | null,
  options?: AxiosInterceptorOptions
) => number;

type AxiosResponseInterceptorUse<T> = (
  onFulfilled?: ((value: T) => T | Promise<T>) | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRejected?: ((error: any) => any) | null
) => number;

interface InterceptorHandler<V, T = V> {
  fulfilled: ((value: V) => T | Promise<T>) | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rejected?: ((error: any) => any) | null;
  synchronous?: boolean;
  runWhen?: AxiosInterceptorOptions['runWhen'];
}

/**
 * Interceptor Manager class that has the same behavior as the axios one but can be used with other http client
 */
export class InterceptorManager<T> implements AxiosInterceptorManager<T> {
  handlers: InterceptorHandler<T, T>[];

  constructor() {
    this.handlers = [];
  }

  /**
   * function to register interceptor
   * mimic axios behavior
   */
  use = ((
    onFulfilled?: ((value: T) => T | Promise<T>) | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRejected?: ((error: any) => any) | null,
    options?: AxiosInterceptorOptions
  ): number => {
    const handler: InterceptorHandler<T, T> = {
      // Cast here to support same interface as axios
      fulfilled: onFulfilled as unknown as (value: T) => T | Promise<T>,
      rejected: onRejected,
      synchronous: !!options?.synchronous,
      runWhen: options?.runWhen,
    };
    this.handlers.push(handler);
    return this.handlers.length - 1;
  }) as T extends AxiosResponse
    ? AxiosResponseInterceptorUse<T>
    : AxiosRequestInterceptorUse<T>;

  /**
   * Necessary to match axios interface
   */
  eject(id: number): void {
    if (this.handlers[id]) {
      this.handlers.splice(id, 1);
    }
  }

  /**
   * Necessary to match axios interface
   */
  clear(): void {
    if (this.handlers) {
      this.handlers = [];
    }
  }

  /**
   * Necessary to match axios interface
   */
  getHandlers(): (InterceptorHandler<T, unknown> | null)[] {
    return this.handlers;
  }

  /**
   * Fonction to compose all declared success interceptor
   * Only handle case where in the end we expect an AxiosReponse like result
   */
  getComposedFulfilledHandler(): (
    response: T | Promise<T> | null
  ) => Promise<T | null> {
    return async (response: T | Promise<T> | null) => {
      let acc: T | Promise<T> | null = response;
      // eslint-disable-next-line no-restricted-syntax
      for (const handler of this.handlers) {
        if (handler.fulfilled) {
          // eslint-disable-next-line no-await-in-loop
          acc = await handler.fulfilled(acc as T);
        }
      }
      return acc;
    };
  }

  /**
   * Fonction to compose all declared error interceptor
   * have to keep the any in the interface as axios use it as well
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getComposedRejectedHandler(): (error: any) => Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (error: any) =>
      this.handlers.reduce((acc, handler) => handler.rejected?.(acc), error);
  }

  /**
   * Necessary to match axios interface
   */
  forEach(fn: (handler: InterceptorHandler<T, unknown> | null) => void): void {
    this.handlers.forEach((handler) => {
      if (handler !== null) {
        fn(handler);
      }
    });
  }
}
