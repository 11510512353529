import {
  Image,
  StreamLineIcon,
  StreamLineIconList,
} from '@legalplace/storybook';

import { AddBankAccountContainer } from './AddAccountModal.style';

export function BankAccountElement({
  onClick,
  isLoading,
  logo,
}: {
  onClick: () => void;
  isLoading: boolean;
  logo: {
    src: string;
    alt: string;
  };
}): JSX.Element {
  return (
    <AddBankAccountContainer isLoading={isLoading} onClick={onClick}>
      <Image src={logo.src} alt={logo.alt} height="1.8rem" />
      <StreamLineIcon height="2rem" icon={StreamLineIconList.arrowright1} />
    </AddBankAccountContainer>
  );
}
