/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type {
  HttpHeaders,
  HttpResponse,
  HttpResponseType,
} from '@capacitor/core';
import { CapacitorHttp } from '@capacitor/core';

import { NO_CONNECTION_TOAST_DEBOUNCE_TIME } from '../../constants';
import { showToastError } from '../notifications/toast';

import { InterceptorManager } from './interceptor.manager';

/**
 * Capacitor class to mimic axios behavior on native device
 */
export class CapacitorHttpClient {
  config?: AxiosRequestConfig;

  interceptors: {
    request: InterceptorManager<AxiosRequestConfig>;
    response: InterceptorManager<AxiosResponse>;
  };

  lastNoConnectionToastTimestamp: number;

  constructor(config?: AxiosRequestConfig) {
    this.config = config;
    this.interceptors = {
      request: new InterceptorManager<AxiosRequestConfig>(),
      response: new InterceptorManager<AxiosResponse>(),
    };
    this.lastNoConnectionToastTimestamp = 0;
  }

  async get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    if (config?.responseType === 'stream') {
      throw new Error('Not yet implemented');
    }

    const getResponse = await this.noInternetConnectionWrapper(() =>
      CapacitorHttp.get({
        url: this.config?.baseURL ? `${this.config?.baseURL}${url}` : url,
        responseType: config?.responseType as unknown as HttpResponseType,
        headers: this.config?.headers as HttpHeaders,
      })
    );
    if (config?.responseType === 'blob') {
      getResponse.data = new Blob([Buffer.from(getResponse.data, 'base64')], {
        type: getResponse.headers['Content-Type'],
      });
    }

    return this.handleRequestWithInterceptors({
      ...getResponse,
      statusText: '',
      config: config || {},
    });
  }

  async post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.handleRequestWithInterceptors({
      ...(await this.noInternetConnectionWrapper(() =>
        CapacitorHttp.post({
          url: this.config?.baseURL ? `${this.config?.baseURL}${url}` : url,
          headers: {
            ...(this.config?.headers as HttpHeaders),
            'Content-Type': 'application/json',
          },
          data,
        })
      )),
      statusText: '',
      config: config || {},
    });
  }

  async patch<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.handleRequestWithInterceptors({
      ...(await this.noInternetConnectionWrapper(() =>
        CapacitorHttp.patch({
          url: this.config?.baseURL ? `${this.config?.baseURL}${url}` : url,
          headers: {
            ...(this.config?.headers as HttpHeaders),
            'Content-Type': 'application/json',
          },
          data,
        })
      )),
      statusText: '',
      config: config || {},
    });
  }

  async put<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.handleRequestWithInterceptors({
      ...(await this.noInternetConnectionWrapper(() =>
        CapacitorHttp.put({
          url: this.config?.baseURL ? `${this.config?.baseURL}${url}` : url,
          headers: {
            ...(this.config?.headers as HttpHeaders),
            'Content-Type': 'application/json',
          },
          data,
        })
      )),
      statusText: '',
      config: config || {},
    });
  }

  async noInternetConnectionWrapper(fn: () => Promise<any>): Promise<any> {
    if (
      !navigator.onLine &&
      Date.now() - this.lastNoConnectionToastTimestamp >
        NO_CONNECTION_TOAST_DEBOUNCE_TIME
    ) {
      this.lastNoConnectionToastTimestamp = Date.now();
      showToastError(
        'Impossible de réaliser cette action, vérifiez votre connexion internet.'
      );
      return null;
    }
    return fn();
  }

  async delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.handleRequestWithInterceptors({
      ...(await this.noInternetConnectionWrapper(() =>
        CapacitorHttp.delete({
          url: this.config?.baseURL ? `${this.config?.baseURL}${url}` : url,
          headers: this.config?.headers as HttpHeaders,
        })
      )),
      statusText: '',
      config: config || {},
    });
  }

  async request(config?: AxiosRequestConfig): Promise<AxiosResponse> {
    type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

    const methodToMethodMapper: Record<
      HTTPMethod,
      (config: AxiosRequestConfig) => Promise<AxiosResponse>
    > = {
      GET: (getConfig: AxiosRequestConfig) =>
        this.get(getConfig?.url as string, getConfig),
      POST: (postConfig: AxiosRequestConfig) =>
        this.post(postConfig?.url as string, postConfig?.data, postConfig),
      PUT: (putConfig: AxiosRequestConfig) =>
        this.put(putConfig?.url as string, putConfig?.data, putConfig),
      PATCH: (patchConfig: AxiosRequestConfig) =>
        this.patch(patchConfig?.url as string, patchConfig?.data, patchConfig),
      DELETE: (deleteConfig: AxiosRequestConfig) =>
        this.delete(deleteConfig?.url as string, deleteConfig),
    };

    if (
      !config?.method ||
      !Object.keys(methodToMethodMapper).includes(config?.method || '')
    ) {
      throw new Error(
        `Method ${config?.method} is not supported by CapacitorHttpClient`
      );
    }

    return methodToMethodMapper[config.method as HTTPMethod](config);
  }

  private async handleRequestWithInterceptors(
    response: HttpResponse & AxiosResponse
  ): Promise<any> {
    const result =
      await this.interceptors.response.getComposedFulfilledHandler()(
        response as unknown as AxiosResponse
      );

    if (!result) {
      return response;
    }
    if (result.status > 399) {
      const responseError = new Error(
        `Request failed with status code ${result.status} on route ${response.url}`,
        { cause: result.status }
      );
      (responseError as any).response = result;
      (responseError as any).isAxiosError = true;
      return this.interceptors.response.getComposedRejectedHandler()(
        responseError
      );
    }
    return response;
  }
}

const api = new CapacitorHttpClient({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

export default api;
