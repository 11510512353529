export default {
  rib_upload: {
    title: 'Transmettez-nous votre RIB professionnel',
    subtitle:
      'Votre RIB professionnel servira pour la facturation mais aussi pour toutes les démarches administratives de votre entreprise.',
    upload_file: 'Déposer votre RIB',
    allowed_types: 'PDF, PNG, JPEG',
    max_file_size: 'max 5Mo',
    confirm_delete: 'Oui, supprimer',
    cancel_delete: 'Annuler',
    invalid_file:
      'Votre fichier n’a pas pu être téléchargé, vérifiez la taille et le format de celui-ci.',
    confirm_rib: 'Confirmer mon RIB',
    unavailable_in_mobile: {
      title: 'Indisponible sur l’application',
      message:
        'Cette fonctionnalité est pour l’instant uniquement disponible depuis un navigateur. Rendez-vous sur <a href="https://pro.legalplace.fr/{{companyId}}/onboarding-v2">https://pro.legalplace.fr</a> pour poursuivre.',
    },
  },
  rib_selection: {
    no_account: {
      title: 'Informations de facturation et de déclaration',
      message:
        'Ce compte sera utilisé pour vos déclarations impots.gouv ainsi que pour le prélèvement de votre abonnement LegalPlace Pro.',
      button_label: 'Synchroniser mon compte',
      disclaimer: {
        title_no_account: 'Aucun compte n’est synchronisé',
        title_invalid_one: "Le compte synchronisé n'est pas éligible",
        title_invalid_other: 'Les comptes synchronisés ne sont pas éligibles',
        message:
          'Synchronisez un compte avec un IBAN français pour vos démarches et prélèvements.',
      },
    },
    account_selection: {
      title: 'Informations de facturation et de déclaration',
      message:
        'Ce compte sera utilisé pour vos déclarations impots.gouv ainsi que pour le prélèvement de votre abonnement LegalPlace Pro.',
      account_tax_subscription_dropdown_label:
        'Sélectionnez le compte à utiliser pour vos démarches impots.gouv et votre abonnement',
      account_tax_dropdown_label:
        'Sélectionnez le compte à utiliser pour vos démarches impots.gouv',
      account_subscription_dropdown_label:
        'Sélectionnez le compte pour le prélèvement de votre abonnement',
      checkbox_label:
        'Je souhaite utiliser un autre compte pour le prélèvement de mon abonnement',
      submit_label_many_accounts: 'Valider mon choix',
      submit_label_one_account: 'Valider',
      synchronize_disclaimer: {
        title: "Vous ne serez prélevé qu'à l'issue de votre période d'essai",
      },
      error: {
        dropdown_fr_iban_only: 'Veuillez sélectionner un IBAN français',
        dropdown_invalid_account:
          'Veuillez réessayer ou sélectionner un autre compte',
        account_validation_error:
          'Impossible de valider ce compte, veuillez réessayer ou synchroniser un autre compte',
      },
    },
  },
};
