import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountType } from '@legalplace/shared';
import type { ISelectOption } from '@legalplace/storybook';
import {
  Dropdown,
  FlexColumnFullWidth,
  Typography,
} from '@legalplace/storybook';

import { useAppSelector } from '../../../../../hooks/store';
import type { IRibFormState } from '../../../../../interfaces/forms/rib.interface';
import { validateIbanForAccountId } from '../../../../../services/api/account.api';
import { selectAccountsById } from '../../../../../store/accounts/selectors';
import { InputWrapper } from '../../../../common/forms/inputWrapper/InputWrapper';

import { getHasOnlyOneAccountWithFrenchIban } from './AccountSelection.helper';

import { UniqueAccountContainer } from './AccountSelectionForm.style';

interface IAccountSelectionFormProps {
  ibanDropdownLabel: string;
  formState: IRibFormState;
  isSubmitting: boolean;
}

export function AccountSelectionForm({
  ibanDropdownLabel,
  formState: { values, setValues, errors, setErrors, isLoading, setIsLoading },
  isSubmitting,
}: IAccountSelectionFormProps): JSX.Element {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'contract_signature.rib_selection.account_selection',
  });
  const accountsById = useAppSelector(selectAccountsById);
  const allAccounts = Object.values(accountsById);
  const [selectedAccountId, setSelectedAccountId] = useState(values.accountId);

  const accountOptions = useMemo(
    () =>
      allAccounts
        .filter(
          (account) =>
            (account.iban && account.accountStatus === 0) ||
            account.externalAccountType === AccountType.LPPRO
        )
        .map((account) => ({
          label: `${account.bankName} - ${account.iban}`,
          value: account.id,
          selected: account.id === selectedAccountId,
        })),
    [allAccounts, selectedAccountId]
  );

  const hasOnlyOneAccountWithFrenchIban = useMemo(
    () => getHasOnlyOneAccountWithFrenchIban(allAccounts),
    [allAccounts]
  );

  const handleDropdownChange = async (
    option?: ISelectOption | undefined
  ): Promise<void> => {
    const accountId = option?.value as string;
    setSelectedAccountId(accountId);
    setValues({
      accountId,
      iban: accountsById[accountId].iban,
    });
    setErrors({});

    if (!accountsById[accountId].iban?.startsWith('FR')) {
      setErrors({
        accountIdErrorMessage: t('error.dropdown_fr_iban_only'),
      });
      return;
    }

    setIsLoading(true);
    try {
      const {
        data: { valid },
      } = await validateIbanForAccountId(accountId);
      if (!valid) {
        setErrors({
          accountIdErrorMessage: t('error.dropdown_invalid_account'),
        });
      }
    } catch (error) {
      console.error('Error while validating IBAN', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FlexColumnFullWidth gap={{ default: '0.8rem' }}>
      {hasOnlyOneAccountWithFrenchIban ? (
        <UniqueAccountContainer>
          <Typography
            tag="p5"
            color="grey600"
            lineHeight={{ default: '2rem' }}
            fontWeight="500"
          >
            {selectedAccountId ? accountsById[selectedAccountId]?.bankName : ''}
          </Typography>
          <Typography
            tag="p4"
            color="primary900"
            lineHeight={{ default: '2rem' }}
            fontWeight="500"
          >
            {selectedAccountId ? accountsById[selectedAccountId]?.iban : ''}
          </Typography>
        </UniqueAccountContainer>
      ) : (
        <InputWrapper name="account_dropdown" label={ibanDropdownLabel}>
          <Dropdown
            options={accountOptions}
            defaultOption={accountOptions.find(
              (option) => option.value === selectedAccountId
            )}
            onChange={handleDropdownChange}
            height={{ default: '3.6rem' }}
            disabled={isLoading || isSubmitting}
            hasError={!!errors.accountIdErrorMessage}
            errorMessage={errors.accountIdErrorMessage}
          />
        </InputWrapper>
      )}
    </FlexColumnFullWidth>
  );
}
