import type { PresignedFileUploadDto } from '@legalplace/bankroot-api/modules/file/dto';
import type {
  DepreciationRowDto,
  GetImmobilisationDetailsDto,
  GetImmobilisationDto,
  ImmobilisationStatsDto,
  SellImmobilisationRequestDto,
  SimilarImmobilisationDto,
  UpdateImmobilisationRequestDto,
} from '@legalplace/bankroot-api/modules/immobilisation/dto';
import type { OperationDto } from '@legalplace/bankroot-api/modules/immobilisation/dto/operation.dto';
import { createApi } from '@reduxjs/toolkit/query/react';

import { reactToolkitBaseQuery } from '../../services/api/api';

export const immobilisationApi = createApi({
  reducerPath: 'immobilisationApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['Immobilisation', 'ImmobilisationInfo'],
  endpoints: (build) => ({
    getImmobilisations: build.query<GetImmobilisationDto[], string>({
      query: (companyId) => ({ url: `/immobilisation?companyId=${companyId}` }),
      providesTags: ['Immobilisation'],
    }),
    getImmobilisationDepreciationPreview: build.query<
      DepreciationRowDto[],
      {
        immobilisationId: string;
        duration: number;
        capitalizationDate: string;
        totalAmount?: string;
      }
    >({
      query: ({
        immobilisationId,
        duration,
        capitalizationDate,
        totalAmount,
      }) => ({
        url: `/immobilisation/${immobilisationId}/depreciation-preview?duration=${duration}&capitalizationDate=${encodeURIComponent(
          capitalizationDate
        )}${totalAmount ? `&totalAmount=${totalAmount}` : ''}`,
      }),
      providesTags: ['Immobilisation'],
    }),
    getImmobilisation: build.query<GetImmobilisationDetailsDto, string>({
      query: (immobilisationId) => ({
        url: `/immobilisation/${immobilisationId}`,
      }),
      providesTags: ['ImmobilisationInfo'],
    }),
    getSimilarImmobilisations: build.query<SimilarImmobilisationDto[], string>({
      query: (immobilisationId) => ({
        url: `/immobilisation/${immobilisationId}/similar`,
      }),
      providesTags: ['ImmobilisationInfo'],
    }),
    getImmobilisationFileDownloadUrl: build.mutation<
      string,
      { immobilisationId: string; fileId: string }
    >({
      query: ({ immobilisationId, fileId }) => ({
        url: `/immobilisation/${immobilisationId}/file/${fileId}/download-url`,
        method: 'GET',
      }),
    }),
    getImmobilisationFileUploadUrl: build.mutation<
      PresignedFileUploadDto,
      { immobilisationId: string; fileName: string }
    >({
      query: ({ immobilisationId, fileName }) => ({
        url: `/immobilisation/${immobilisationId}/file/upload?fileName=${encodeURIComponent(
          fileName
        )}`,
        method: 'GET',
      }),
    }),
    attachFileToImmobilisation: build.mutation<
      PresignedFileUploadDto,
      { immobilisationId: string; fileId: string }
    >({
      query: ({ immobilisationId, fileId }) => ({
        url: `/immobilisation/${immobilisationId}/file`,
        method: 'POST',
        body: { fileId },
      }),
      invalidatesTags: ['ImmobilisationInfo'],
    }),
    deleteFileToImmobilisation: build.mutation<
      void,
      { immobilisationId: string; fileId: string }
    >({
      query: ({ immobilisationId, fileId }) => ({
        url: `/immobilisation/${immobilisationId}/file/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ImmobilisationInfo'],
    }),
    updateImmobilisation: build.mutation<
      void,
      {
        immobilisationId: string;
        updateImmobilisationRequestDto: UpdateImmobilisationRequestDto;
      }
    >({
      query: ({ immobilisationId, updateImmobilisationRequestDto }) => ({
        url: `/immobilisation/${immobilisationId}`,
        method: 'PATCH',
        body: updateImmobilisationRequestDto,
      }),
      invalidatesTags: ['Immobilisation', 'ImmobilisationInfo'],
    }),
    getImmobilisationStats: build.query<ImmobilisationStatsDto, string>({
      query: (companyId) => ({
        url: `/immobilisation/stats?companyId=${companyId}`,
      }),
      providesTags: ['Immobilisation'],
    }),
    deleteImmobilisation: build.mutation<void, string>({
      query: (immobilisationId) => ({
        url: `/immobilisation/${immobilisationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Immobilisation'],
    }),
    getPotentialSaleOperationsForImmobilisation: build.query<
      OperationDto[],
      string
    >({
      query: (immobilisationId) => ({
        url: `/immobilisation/${immobilisationId}/potential-sale-operations`,
      }),
      providesTags: ['Immobilisation'],
    }),
    sellImmobilisation: build.mutation<
      void,
      {
        immobilisationId: string;
        sellImmobilisationRequestDto: SellImmobilisationRequestDto;
      }
    >({
      query: ({ immobilisationId, sellImmobilisationRequestDto }) => ({
        url: `/immobilisation/${immobilisationId}/sell`,
        method: 'PATCH',
        body: sellImmobilisationRequestDto,
      }),
      invalidatesTags: ['Immobilisation', 'ImmobilisationInfo'],
    }),
    associateImmobilisation: build.mutation<
      void,
      { immobilisationId: string; immobilisationToAssociateIds: string[] }
    >({
      query: ({ immobilisationId, immobilisationToAssociateIds }) => ({
        url: `/immobilisation/${immobilisationId}/associate`,
        method: 'POST',
        body: { immobilisationToAssociateIds },
      }),
      invalidatesTags: ['Immobilisation', 'ImmobilisationInfo'],
    }),
  }),
});

export const {
  useGetImmobilisationsQuery,
  useGetImmobilisationQuery,
  useGetSimilarImmobilisationsQuery,
  useGetImmobilisationDepreciationPreviewQuery,
  useGetImmobilisationFileDownloadUrlMutation,
  useGetImmobilisationFileUploadUrlMutation,
  useAttachFileToImmobilisationMutation,
  useDeleteFileToImmobilisationMutation,
  useUpdateImmobilisationMutation,
  useGetImmobilisationStatsQuery,
  useDeleteImmobilisationMutation,
  useGetPotentialSaleOperationsForImmobilisationQuery,
  useSellImmobilisationMutation,
  useAssociateImmobilisationMutation,
} = immobilisationApi;
