export class WithContextError extends Error {
  public context: { [contextKey: string]: Record<string, unknown> };

  constructor(
    message: string,
    contextKey: string,
    contextObject: Record<string, unknown>
  ) {
    super(
      `${message} -> See values in 'Contexts' section -> category '${contextKey}'`
    );
    this.name = 'WithContextError';
    this.context = { [contextKey]: contextObject };
  }
}
