import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { OnboardingStep } from '@legalplace/shared';
import {
  FlexContainerBasicColumn,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { selectAccountsAllIds } from '../../../../../store/accounts/selectors';
import { selectActiveCompanyId } from '../../../../../store/companies/selectors';
import {
  startFetchOnboardingSteps,
  unskipOnboardingStep,
} from '../../../../../store/onboardingSteps/actions';
import { selectSkippedSteps } from '../../../../../store/onboardingSteps/selector';

import { TitleIconContainer } from './AccountSelectionForm.style';
import {
  RibSelectionContainer,
  SynchronizeButton,
  SynchronizeDisclaimer,
} from './NoAccount.style';

export function NoAccount(): JSX.Element {
  const { t } = useTranslation('onboarding_v2', {
    keyPrefix: 'contract_signature.rib_selection.no_account',
  });
  const dispatch = useAppDispatch();
  const skippedSteps = useAppSelector(selectSkippedSteps);
  const activeCompanyId = useAppSelector(selectActiveCompanyId);
  const accountsAllIds = useAppSelector(selectAccountsAllIds);
  const navigate = useNavigate();

  const isBankSyncSkipped = skippedSteps.includes(
    OnboardingStep.BankAccountsSynchronization
  );

  const handleSynchronizeClick = (): void => {
    if (isBankSyncSkipped) {
      dispatch(
        unskipOnboardingStep(OnboardingStep.BankAccountsSynchronization)
      );
      dispatch(startFetchOnboardingSteps({ silent: true }));
    } else {
      navigate({
        pathname: `/${activeCompanyId}/comptes`,
        search: window.location.search,
      });
    }
  };

  return (
    <RibSelectionContainer>
      <FlexContainerBasicColumn gap={{ default: '0.8rem' }}>
        <Typography
          tag="p"
          color="primary900"
          lineHeight={{ default: '2rem' }}
          fontWeight="500"
        >
          {t('title')}
        </Typography>
        <Typography
          tag="p4"
          color="primary900"
          lineHeight={{ default: '2rem' }}
          fontWeight="400"
        >
          {t('message')}
        </Typography>
      </FlexContainerBasicColumn>
      <SynchronizeDisclaimer>
        <TitleIconContainer>
          <StreamLineIcon
            icon={StreamLineIconList.alerttriangle}
            fillColor="warning500"
          />
          <Typography
            tag="p4"
            color="warning900"
            lineHeight={{ default: '2rem' }}
            fontWeight="600"
          >
            {isBankSyncSkipped
              ? t('disclaimer.title_no_account')
              : t('disclaimer.title_invalid', { count: accountsAllIds.length })}
          </Typography>
        </TitleIconContainer>
        <Typography
          tag="p5"
          color="warning900"
          lineHeight={{ default: '1.6rem' }}
          fontWeight="400"
        >
          {t('disclaimer.message')}
        </Typography>
      </SynchronizeDisclaimer>
      <SynchronizeButton styleType="primary" onClick={handleSynchronizeClick}>
        <Typography
          tag="p4"
          color="white"
          lineHeight={{ default: '1.6rem' }}
          fontWeight="400"
        >
          {t('button_label')}
        </Typography>
      </SynchronizeButton>
    </RibSelectionContainer>
  );
}
