import { type FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaxCredentialsStatus } from '@legalplace/shared';
import {
  BannerStyle,
  BannerV2,
  FlexContainerBasicAlignCenter,
  FlexContainerBasicColumn,
  ModalDrawerV2,
  ModalSizes,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { INTERCOM_SECRET_ANSWER_ARTICLE } from '../../../constants/taxCredentials.constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { startFetchCompanies } from '../../../store/companies/actions';
import { selectActiveCompany } from '../../../store/companies/selectors';
import {
  useGetTaxesCredentialsQuery,
  useUpdateTaxCredentialsMutation,
} from '../../../store/taxesCredentials/reducer';
import { InputWrapper } from '../../common/forms/inputWrapper/InputWrapper';
import { PasswordField } from '../../common/forms/passwordField/PasswordField';
import { Input } from '../../common/forms/styledInput/Input';

import { PrimaryButton } from '../../UI/PrimaryButton.style';

interface ITaxCredentialsModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

export function TaxCredentialsModal({
  isModalOpen,
  setIsModalOpen,
}: ITaxCredentialsModalProps): JSX.Element {
  const { t } = useTranslation('settings', {
    keyPrefix: 'settings_navigation_details.tax_credentials',
  });
  const dispatch = useAppDispatch();
  const [updateTaxCredentialsRequest] = useUpdateTaxCredentialsMutation();
  const activeCompany = useAppSelector(selectActiveCompany);
  const activeCompanyId = activeCompany?.id;
  const hasActiveAccountingSubscription =
    activeCompany?.featureMap.ComptaBase?.isActive;
  const { data = { taxCredentialStatus: TaxCredentialsStatus.Valid } } =
    useGetTaxesCredentialsQuery(activeCompanyId ?? '', {
      skip: !activeCompanyId || !hasActiveAccountingSubscription,
    });
  const [taxesCredentials, setTaxesCredentials] = useState<{
    username: string;
    password: string;
    secretAnswer?: string;
  }>({ username: '', password: '' });

  const { taxCredentialStatus: taxesCredentialsStatus } = data;
  const [isLoading, setIsLoading] = useState(false);
  const [hasSecretAnswerError, setHasSecretAnswerError] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState(false);

  const editOrSubmitTaxCredentials: FormEventHandler<HTMLFormElement> = async (
    event
  ): Promise<void> => {
    event.preventDefault();

    if (!activeCompany) {
      return;
    }
    const isSecretAnswerMandatoryAndEmpty =
      (taxesCredentialsStatus === TaxCredentialsStatus.Valid ||
        taxesCredentialsStatus === TaxCredentialsStatus.CredentialsChanged) &&
      !taxesCredentials?.secretAnswer;

    if (
      !taxesCredentials?.username ||
      !taxesCredentials?.password ||
      isSecretAnswerMandatoryAndEmpty
    ) {
      setHasSecretAnswerError(true);
      return;
    }
    try {
      setIsLoading(true);
      await updateTaxCredentialsRequest({
        taxesCredentials,
        companyId: activeCompany.id,
      }).unwrap();
      setTaxesCredentials({ username: '', password: '' });
      setIsModalOpen(false);
      dispatch(startFetchCompanies());
    } catch (error: unknown) {
      setHasPasswordError(true);
      console.error(
        `[editOrSubmitTaxCredentials] - Error while editing or submitting tax credentials: ${error}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClose = (): void => {
    setIsModalOpen(false);
  };

  return (
    <ModalDrawerV2
      isOpen={isModalOpen}
      onClose={handleOnClose}
      onBackdropClick={handleOnClose}
      title={t('modal_title')}
      size={ModalSizes.MEDIUM}
    >
      <BannerV2
        bannerStyle={BannerStyle.INFORMATION}
        title={t('info_box_title')}
        message={t('info_box_message')}
        spacing={{ default: '0 0 1.6rem 0' }}
        icon={StreamLineIconList.informationcircle}
        linkButtonLabel={t('learn_more_button_label')}
        linkButtonHref={INTERCOM_SECRET_ANSWER_ARTICLE}
      />
      <form onSubmit={editOrSubmitTaxCredentials}>
        <InputWrapper name="emailField" label={t('email_field')}>
          <Input
            name="email"
            type="email"
            placeholder={t('email_placeholder')}
            placeholderColor="grey300"
            onChange={(event) =>
              setTaxesCredentials((previousValue) => ({
                ...previousValue,
                username: event.target.value,
              }))
            }
            hasError={false}
            value={taxesCredentials?.username ?? ''}
          />
        </InputWrapper>
        <InputWrapper
          name="passwordField"
          label={t('password_field')}
          hasError={hasPasswordError}
          errorMessage={t('error.same_password')}
        >
          <PasswordField
            name="newPasswordConfirmation"
            placeholder={t('password_placeholder')}
            onChange={(event) => {
              setHasPasswordError(false);
              setTaxesCredentials((previousValue) => ({
                ...previousValue,
                password: event.target.value,
              }));
            }}
            placeholderColor="grey300"
            color="grey700"
            hasError={hasPasswordError}
            value={taxesCredentials?.password ?? ''}
          />
        </InputWrapper>
        <InputWrapper
          name="secretAnswerField"
          label={t('secret_question_field')}
          tooltipMessage={t('secret_question_tooltip')}
        >
          <PasswordField
            name="secretAnswerFieldConfirmation"
            placeholder={t('secret_question_placeholder')}
            placeholderColor="grey300"
            color="grey700"
            hasError={hasSecretAnswerError}
            value={taxesCredentials?.secretAnswer ?? ''}
            onChange={(event) => {
              setHasSecretAnswerError(false);
              setTaxesCredentials((previousValue) => ({
                ...previousValue,
                secretAnswer: event.target.value,
              }));
            }}
          />
        </InputWrapper>

        <FlexContainerBasicAlignCenter gap={{ default: '0.8rem' }}>
          <StreamLineIcon icon={StreamLineIconList.lock} fillColor="grey400" />
          <FlexContainerBasicColumn>
            <Typography
              tag="p5"
              fontWeight="500"
              color="greytainted400"
              textAlign="left"
            >
              {t('encrypted_informations')}
            </Typography>
            <Typography
              tag="p5"
              fontWeight="500"
              color="greytainted400"
              textAlign="left"
            >
              {t('processing_information')}
            </Typography>
          </FlexContainerBasicColumn>
        </FlexContainerBasicAlignCenter>
        <PrimaryButton
          type="submit"
          width={{ default: '100%', medium: 'fit-content' }}
          spacing={{ default: '4rem 0 0 auto' }}
          isLoading={isLoading}
          disabled={
            !taxesCredentials?.username ||
            !taxesCredentials?.password ||
            hasSecretAnswerError
          }
        >
          {t('submit_button_label')}
        </PrimaryButton>
      </form>
    </ModalDrawerV2>
  );
}
