import type { SalesReportsHistoryResponse } from '@legalplace/bankroot-api/modules/salesReport/dto';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../constants';

import type { SalesReportsState } from './types';

const initialState: SalesReportsState = {
  monthReportsByMonthAndYear: {},
  status: Status.LOADING,
  pageStatus: Status.IDLE,
  lastStartDateFetched: undefined,
};

export const salesReportsSlice = createSlice({
  name: 'salesReports',
  initialState,
  reducers: {
    startFetchSalesMonthReportsRequest: (
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>
    ) => {
      state.pageStatus = Status.LOADING;
      state.lastStartDateFetched = action.payload.startDate;
    },
    fetchSalesMonthReportsSuccess: (
      state,
      action: PayloadAction<SalesReportsHistoryResponse[]>
    ) => {
      state.monthReportsByMonthAndYear = action.payload.reduce(
        (acc, monthReport) => ({
          ...acc,
          [`${monthReport.month}-${monthReport.year}`]: monthReport,
        }),
        state.monthReportsByMonthAndYear
      );
      state.status = Status.IDLE;
      state.pageStatus = Status.IDLE;
    },
    failSalesMonthReportsRequest: (state) => {
      state.status = Status.FAILED;
    },
    resetSalesReports: () => initialState,
  },
});

export default salesReportsSlice.reducer;
