import type { TFunction } from 'react-i18next';

import { type FeatureMap } from '@legalplace/shared';
import { StreamLineIconList } from '@legalplace/storybook';

import {
  GROUP_ID_ACCOUNTING,
  GROUP_ID_BANK,
  GROUP_ID_BASIC,
  GROUP_ID_BASIC_DASHBOARD,
  GROUP_ID_INVOICE,
  GROUP_ID_UPSELL,
} from '../../constants';
import type { INavigationListItem } from '../../interfaces';

export const buildAccountingNavigationItemList = (
  t: TFunction,
  companyId?: string,
  isDesktop?: boolean,
  hasNoAccounts?: boolean,
  shouldShowBankUpsell?: boolean,
  shouldShowMyAccountantPage?: boolean,
  shouldShowImmobilisationsPage?: boolean,
  shouldShowSalesReportPage?: boolean
): INavigationListItem[] => [
  {
    id: 'tableau-de-bord',
    label: t('dashboard'),
    uri: `/${companyId}/tableau-de-bord`,
    icon: StreamLineIconList.Dashboard,
    groupId: GROUP_ID_BASIC,
    showSeparator: true,
  },
  ...(hasNoAccounts
    ? []
    : [
        {
          id: 'transaction',
          label: t('transactions'),
          uri: `/${companyId}`,
          icon: StreamLineIconList.moneywallet1,
          groupId: GROUP_ID_BASIC,
        },
      ]),
  {
    id: 'notes-de-frais',
    label: t('expenses'),
    uri: `/${companyId}/notes-de-frais`,
    icon: StreamLineIconList.receiptslip1,
    groupId: GROUP_ID_BASIC,
    showSeparator: !isDesktop,
  },
  ...(shouldShowImmobilisationsPage
    ? [
        {
          id: 'immobilisations',
          label: t('immobilisations'),
          uri: `/${companyId}/immobilisations`,
          icon: StreamLineIconList.iconeditedpiggyBank,
          groupId: GROUP_ID_BASIC,
          showSeparator: !isDesktop,
        },
      ]
    : []),
  ...(shouldShowSalesReportPage
    ? [
        {
          id: 'declaration-des-ventes',
          label: t('sales_report'),
          uri: `/${companyId}/declaration-des-ventes`,
          icon: StreamLineIconList.iconeditedwebMoneyTouch,
          groupIcon: StreamLineIconList.accountingcalculator1,
          groupId: GROUP_ID_BASIC,
          showSeparator: !isDesktop,
        },
      ]
    : []),
  ...(isDesktop
    ? [
        {
          id: 'comptabilite',
          label: t('deadlines'),
          uri: `/${companyId}/comptabilite`,
          icon: StreamLineIconList.calendar3,
          groupId: GROUP_ID_BASIC,
          showSeparator: !shouldShowMyAccountantPage,
        },
      ]
    : []),
  ...(shouldShowMyAccountantPage
    ? [
        {
          id: 'mon-comptable',
          label: t('my_accountant'),
          uri: `/${companyId}/mon-comptable`,
          icon: StreamLineIconList.iconeditedpeopleShield,
          groupId: GROUP_ID_BASIC,
          showSeparator: true,
        },
      ]
    : []),
  ...(shouldShowBankUpsell
    ? [
        {
          id: 'decouvrir-compte-pro',
          label: t('accounting_to_bank_upsell'),
          uri: `/${companyId}/decouvrir-compte-pro`,
          icon: StreamLineIconList.creditcard1,
          groupId: GROUP_ID_UPSELL,
          tag: t('accounting_to_bank_upsell_tag'),
          showSeparator: true,
        },
      ]
    : []),
  {
    id: 'factures',
    label: t('invoices'),
    uri: `/${companyId}/factures`,
    icon: StreamLineIconList.commonfiletext,
    groupId: GROUP_ID_INVOICE,
    groupLabel: t('invoice_group_label'),
    groupIcon: StreamLineIconList.commonfiletext,
  },
  {
    id: 'devis',
    label: t('quote'),
    uri: `/${companyId}/factures/devis`,
    icon: StreamLineIconList.store,
    groupId: GROUP_ID_INVOICE,
    groupLabel: t('invoice_group_label'),
    groupIcon: StreamLineIconList.newspaperfold,
  },
  {
    id: 'clients',
    label: t('client'),
    uri: `/${companyId}/factures/clients`,
    icon: StreamLineIconList.multipleneutral2,
    groupId: GROUP_ID_INVOICE,
    groupLabel: t('invoice_group_label'),
    groupIcon: StreamLineIconList.commonfiletext,
  },
  {
    id: 'prestations',
    label: t('prestation'),
    uri: `/${companyId}/factures/prestations`,
    icon: StreamLineIconList.store,
    groupId: GROUP_ID_INVOICE,
    groupLabel: t('invoice_group_label'),
    groupIcon: StreamLineIconList.commonfiletext,
  },
];

export const buildBankNavigationItemList = ({
  t,
  companyId,
  hasInvoicing,
  shouldShowAccountingUpsell,
}: {
  t: TFunction;
  companyId?: string;
  hasInvoicing?: boolean;
  shouldShowAccountingUpsell?: boolean;
}): INavigationListItem[] => [
  {
    id: 'tableau-de-bord',
    label: t('dashboard'),
    uri: `/${companyId}/tableau-de-bord`,
    icon: StreamLineIconList.Dashboard,
    groupId: GROUP_ID_BASIC,
    showSeparator: true,
  },
  {
    id: 'transaction',
    label: t('transactions'),
    uri: `/${companyId}`,
    icon: StreamLineIconList.moneywallet1,
    groupId: GROUP_ID_BASIC,
  },
  {
    id: 'cartes',
    label: t('cards'),
    uri: `/${companyId}/cartes`,
    icon: StreamLineIconList.creditcard1,
    groupId: GROUP_ID_BASIC,
  },
  {
    id: 'virements',
    label: t('credit_transfers'),
    uri: `/${companyId}/virements`,
    icon: StreamLineIconList.datatransferdiagonal,
    groupId: GROUP_ID_BASIC,
  },
  {
    id: 'prelevements',
    label: t('direct_debits'),
    uri: `/${companyId}/prelevements`,
    icon: StreamLineIconList.diagramupdouble,
    groupId: GROUP_ID_BASIC,
    showSeparator: true,
  },
  ...(shouldShowAccountingUpsell
    ? [
        {
          id: 'decouvrir-comptabilite',
          label: t('banking_to_accounting_upsell'),
          uri: `/${companyId}/decouvrir-comptabilite`,
          icon: StreamLineIconList.accountingcalculator1,
          groupId: GROUP_ID_UPSELL,
          tag: t('banking_to_accounting_upsell_tag'),
          showSeparator: true,
        },
      ]
    : []),
  ...(hasInvoicing
    ? [
        {
          id: 'factures',
          label: t('invoices'),
          uri: `/${companyId}/factures`,
          icon: StreamLineIconList.commonfiletext,
          groupId: GROUP_ID_INVOICE,
          groupLabel: t('invoice_group_label'),
          groupIcon: StreamLineIconList.commonfiletext,
        },
        {
          id: 'devis',
          label: t('quote'),
          uri: `/${companyId}/factures/devis`,
          icon: StreamLineIconList.store,
          groupId: GROUP_ID_INVOICE,
          groupLabel: t('invoice_group_label'),
          groupIcon: StreamLineIconList.newspaperfold,
        },
        {
          id: 'clients',
          label: t('client'),
          uri: `/${companyId}/factures/clients`,
          icon: StreamLineIconList.multipleneutral2,
          groupId: GROUP_ID_INVOICE,
          groupLabel: t('invoice_group_label'),
          groupIcon: StreamLineIconList.commonfiletext,
        },
        {
          id: 'prestations',
          label: t('prestation'),
          uri: `/${companyId}/factures/prestations`,
          icon: StreamLineIconList.store,
          groupId: GROUP_ID_INVOICE,
          groupLabel: t('invoice_group_label'),
          groupIcon: StreamLineIconList.commonfiletext,
        },
      ]
    : []),
];

export const buildCompleteNavigationItemList = ({
  t,
  companyId,
  isDesktop,
  hasNoAccounts,
  shouldShowMyAccountantPage,
  shouldShowImmobilisationsPage,
  shouldShowSalesReportPage,
}: {
  t: TFunction;
  companyId?: string;
  isDesktop?: boolean;
  hasNoAccounts?: boolean;
  shouldShowMyAccountantPage?: boolean;
  shouldShowImmobilisationsPage?: boolean;
  shouldShowSalesReportPage?: boolean;
}): INavigationListItem[] => [
  {
    id: 'tableau-de-bord',
    label: t('dashboard'),
    uri: `/${companyId}/tableau-de-bord`,
    icon: StreamLineIconList.Dashboard,
    groupId: GROUP_ID_BASIC_DASHBOARD,
    showSeparator: true,
  },
  ...(hasNoAccounts
    ? []
    : [
        {
          id: 'transaction',
          label: t('transactions'),
          uri: `/${companyId}`,
          icon: StreamLineIconList.moneywallet1,
          groupId: GROUP_ID_BANK,
          groupLabel: t('bank_group_label'),
          groupIcon: StreamLineIconList.moneywallet1,
          groupShowSeparator: true,
        },
      ]),
  {
    id: 'cartes',
    label: t('cards'),
    uri: `/${companyId}/cartes`,
    icon: StreamLineIconList.creditcard1,
    groupId: GROUP_ID_BANK,
    groupLabel: t('bank_group_label'),
    groupIcon: StreamLineIconList.moneywallet1,
    groupShowSeparator: true,
  },
  {
    id: 'virements',
    label: t('credit_transfers'),
    uri: `/${companyId}/virements`,
    icon: StreamLineIconList.datatransferdiagonal,
    groupId: GROUP_ID_BANK,
    groupLabel: t('bank_group_label'),
    groupIcon: StreamLineIconList.moneywallet1,
    groupShowSeparator: true,
  },
  {
    id: 'prelevements',
    label: t('direct_debits'),
    uri: `/${companyId}/prelevements`,
    icon: StreamLineIconList.diagramupdouble,
    groupId: GROUP_ID_BANK,
    groupLabel: t('bank_group_label'),
    groupIcon: StreamLineIconList.moneywallet1,
    groupShowSeparator: true,
  },
  {
    id: 'notes-de-frais',
    label: t('expenses'),
    uri: `/${companyId}/notes-de-frais`,
    icon: StreamLineIconList.receiptslip1,
    groupId: GROUP_ID_ACCOUNTING,
    groupLabel: t('accounting_group_label'),
    groupIcon: StreamLineIconList.accountingcalculator1,
    groupShowSeparator: true,
  },
  ...(shouldShowImmobilisationsPage
    ? [
        {
          id: 'immobilisations',
          label: t('immobilisations'),
          uri: `/${companyId}/immobilisations`,
          icon: StreamLineIconList.iconeditedpiggyBank,
          groupIcon: StreamLineIconList.accountingcalculator1,
          groupId: GROUP_ID_ACCOUNTING,
          showSeparator: !isDesktop,
        },
      ]
    : []),
  ...(shouldShowSalesReportPage
    ? [
        {
          id: 'declaration-des-ventes',
          label: t('sales_report'),
          uri: `/${companyId}/declaration-des-ventes`,
          icon: StreamLineIconList.iconeditedwebMoneyTouch,
          groupIcon: StreamLineIconList.accountingcalculator1,
          groupId: GROUP_ID_ACCOUNTING,
          showSeparator: !isDesktop,
        },
      ]
    : []),
  ...(isDesktop
    ? [
        {
          id: 'comptabilite',
          label: t('deadlines'),
          uri: `/${companyId}/comptabilite`,
          icon: StreamLineIconList.calendar3,
          groupId: GROUP_ID_ACCOUNTING,
          groupLabel: t('accounting_group_label'),
          groupIcon: StreamLineIconList.accountingcalculator1,
          groupShowSeparator: !shouldShowMyAccountantPage,
        },
      ]
    : []),
  ...(shouldShowMyAccountantPage
    ? [
        {
          id: 'mon-comptable',
          label: t('my_accountant'),
          uri: `/${companyId}/mon-comptable`,
          icon: StreamLineIconList.iconeditedpeopleShield,
          groupId: GROUP_ID_ACCOUNTING,
          showSeparator: true,
        },
      ]
    : []),
  {
    id: 'factures',
    label: t('invoices'),
    uri: `/${companyId}/factures`,
    icon: StreamLineIconList.commonfiletext,
    groupId: GROUP_ID_INVOICE,
    groupLabel: t('invoice_group_label'),
    groupIcon: StreamLineIconList.commonfiletext,
  },
  {
    id: 'devis',
    label: t('quote'),
    uri: `/${companyId}/factures/devis`,
    icon: StreamLineIconList.store,
    groupId: GROUP_ID_INVOICE,
    groupLabel: t('invoice_group_label'),
    groupIcon: StreamLineIconList.newspaperfold,
  },
  {
    id: 'clients',
    label: t('client'),
    uri: `/${companyId}/factures/clients`,
    icon: StreamLineIconList.multipleneutral2,
    groupId: GROUP_ID_INVOICE,
    groupLabel: t('invoice_group_label'),
    groupIcon: StreamLineIconList.commonfiletext,
  },
  {
    id: 'prestations',
    label: t('prestation'),
    uri: `/${companyId}/factures/prestations`,
    icon: StreamLineIconList.store,
    groupId: GROUP_ID_INVOICE,
    groupLabel: t('invoice_group_label'),
    groupIcon: StreamLineIconList.commonfiletext,
  },
];

export const buildNavigationItemList = ({
  t,
  companyId,
  featureMap,
  isDesktop,
  isAccountingTrialEnded,
  hasNoAccounts,
  shouldShowBankUpsell,
  shouldShowAccountingUpsell,
  shouldShowMyAccountantPage,
  shouldShowImmobilisationsPage,
  shouldShowSalesReportPage,
}: {
  t: TFunction;
  companyId?: string;
  featureMap?: FeatureMap;
  isDesktop?: boolean;
  isAccountingTrialEnded?: boolean;
  hasNoAccounts?: boolean;
  shouldShowBankUpsell?: boolean;
  shouldShowAccountingUpsell?: boolean;
  shouldShowMyAccountantPage?: boolean;
  shouldShowImmobilisationsPage?: boolean;
  shouldShowSalesReportPage?: boolean;
}): INavigationListItem[] => {
  const hasBank = !!featureMap?.BankBase?.isActive;
  const hasAccounting = !!featureMap?.ComptaBase?.isActive;
  const hasInvoicing = !!featureMap?.Invoicing?.isActive;
  if (hasBank && hasAccounting) {
    return buildCompleteNavigationItemList({
      t,
      companyId,
      isDesktop,
      hasNoAccounts,
      shouldShowMyAccountantPage,
      shouldShowImmobilisationsPage,
      shouldShowSalesReportPage,
    });
  }
  if (hasBank) {
    return buildBankNavigationItemList({
      t,
      companyId,
      hasInvoicing,
      shouldShowAccountingUpsell,
    });
  }

  if (hasAccounting || isAccountingTrialEnded) {
    return buildAccountingNavigationItemList(
      t,
      companyId,
      isDesktop,
      hasNoAccounts,
      shouldShowBankUpsell,
      shouldShowMyAccountantPage,
      shouldShowImmobilisationsPage,
      shouldShowSalesReportPage
    );
  }
  return [];
};

export const buildMenuNavigationItem = (
  t: TFunction,
  companyId?: string
): INavigationListItem => ({
  id: 'menu',
  label: t('menu_button_label'),
  icon: StreamLineIconList.navigationmenu,
  uri: `/${companyId}/menu`,
  groupId: 'menu',
});
