import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BankIconLoader } from '@legalplace/storybook';

import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import { getContractUrlRequest } from '../../../../services/api/onboardingFlow.api';
import { showToastError } from '../../../../services/notifications/toast';
import { delay } from '../../../../services/utils';
import { startFetchCompanies } from '../../../../store/companies/actions';
import { selectActiveCompanyId } from '../../../../store/companies/selectors';
import { useGetFeatureFlagsQuery } from '../../../../store/featureFlags/reducer';
import { selectAccountingOnboardingInformation } from '../../../../store/onboardingSteps/selector';

import { RibSelection } from './RibSelection/RibSelection';
import { AccountingPlanConfirmation } from './AccountingPlanConfirmation';
import { isSignatureCompletedEvent } from './contractSignature.helper';
import { WaitingContract } from './WaitingContract';

export function ContractSignature({
  notifySuccess,
}: {
  notifySuccess: () => void;
}): JSX.Element {
  const [contractSignatureUrl, setContractSignatureUrl] = useState<string>();
  const { companyId } = useParams();
  const { data: featureFlags } = useGetFeatureFlagsQuery(companyId, {
    skip: !companyId,
  });
  const [hasConfirmedAccountingPlan, setHasConfirmedAccountingPlan] =
    useState(false);
  const { isContractSigned, requiresRibUpload, monthlyPrice } =
    useAppSelector(selectAccountingOnboardingInformation) ?? {};
  const [isLoading, setIsLoading] = useState(true);
  const activeCompanyId = useAppSelector(selectActiveCompanyId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('onboarding', { keyPrefix: 'onboarding_v2' });
  const navigate = useNavigate();

  useEffect(() => {
    // If contract is signed and RIB upload is not required, refresh steps because the step is complete
    if (isContractSigned && !requiresRibUpload) {
      notifySuccess();
    }
  }, [isContractSigned, requiresRibUpload, notifySuccess]);

  useEffect(() => {
    if (!activeCompanyId) {
      return;
    }

    const fetchContractSignature = async (): Promise<void> => {
      try {
        const {
          data: { contractUrl },
        } = await getContractUrlRequest(activeCompanyId);
        setContractSignatureUrl(contractUrl);
      } catch (error: unknown) {
        console.error(
          `[ContractSignature] Error while fetching contract signature for companyId ${activeCompanyId}: ${error}`
        );
        showToastError(t('unexpected_error'));
      } finally {
        setIsLoading(false);
      }
    };
    fetchContractSignature();
  }, [activeCompanyId, t]);

  useEffect(() => {
    const updateEngagementLetterStatus = async (
      event: MessageEvent
    ): Promise<void> => {
      if (!isSignatureCompletedEvent(event) || !activeCompanyId) {
        return;
      }
      try {
        await delay(5000);
        dispatch(startFetchCompanies());
        notifySuccess();
      } catch (error: unknown) {
        showToastError(t('unexpected_error'));
        console.error(
          `[ContractSignature] Error while updating engagement letter status for companyId ${activeCompanyId}: ${error}`
        );
      }
    };

    window.addEventListener('message', updateEngagementLetterStatus);

    return () => {
      window.removeEventListener('message', updateEngagementLetterStatus);
    };
  }, [activeCompanyId, dispatch, navigate, notifySuccess, t]);

  const isContractNotReady =
    !contractSignatureUrl &&
    !isContractSigned &&
    !featureFlags?.ACCOUNTING_OFFER_SELECTION_FLAG &&
    !monthlyPrice;

  const isOfferNotReady =
    featureFlags?.ACCOUNTING_OFFER_SELECTION_FLAG && !monthlyPrice;

  if (isLoading) {
    return <BankIconLoader />;
  }

  if (!isContractSigned && contractSignatureUrl) {
    return (
      <iframe
        src={contractSignatureUrl}
        title="contract-signature"
        frameBorder="0"
        height="100%"
        width="100%"
        sandbox="allow-scripts allow-same-origin"
        referrerPolicy="no-referrer"
      />
    );
  }

  if (isContractNotReady || isOfferNotReady) {
    return (
      <WaitingContract
        shouldDisplaySecondLine={!featureFlags?.ACCOUNTING_OFFER_SELECTION_FLAG}
      />
    );
  }

  if (
    !hasConfirmedAccountingPlan &&
    (featureFlags?.ACCOUNTING_OFFER_SELECTION_FLAG || !!monthlyPrice) &&
    !!monthlyPrice &&
    !isContractSigned
  ) {
    return (
      <AccountingPlanConfirmation
        setHasConfirmedAccountingPlan={setHasConfirmedAccountingPlan}
        setContractSignatureUrl={setContractSignatureUrl}
      />
    );
  }

  if (requiresRibUpload) {
    return <RibSelection notifySuccess={notifySuccess} />;
  }

  return <BankIconLoader />;
}
