/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull front_end_client'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 47
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/implementation/front_end_client)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'legalplaceprostaging';

export const ApiKey: Record<Environment, string> = {
  legalplaceprostaging: '2cc907748156a30a9fc4ac6258dc3eab'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '47',
    branch: 'main',
    source: 'front_end_client',
    versionId: '3030380e-d08c-4f8f-be14-8676799e7bff'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * Nombre d'entreprises actives liées à l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  active_company_count?: number;
  /**
   * Date de naissance de l'utilisateur
   */
  birthdate?: string;
  /**
   * Liste des companies du user
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  companies?: string[];
  company?: any;
  company_activity_start_date?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Service, Ecommerce, Commerce, HCR, BTP, Immobilier, Médical, Association, Prof lib, Taxi - VTC, Artisan, Crypto - Blockchain - NFT, Consultant, Transport routier, Autres |
   */
  company_activity_type?:
    | "Service"
    | "Ecommerce"
    | "Commerce"
    | "HCR"
    | "BTP"
    | "Immobilier"
    | "Médical"
    | "Association"
    | "Prof lib"
    | "Taxi - VTC"
    | "Artisan"
    | "Crypto - Blockchain - NFT"
    | "Consultant"
    | "Transport routier"
    | "Autres";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  company_closing_month?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Pré-compta |
   */
  company_compta_plan?: "Pré-compta";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  company_compta_plan_yearly_value?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Paused, ToActivate, PaymentFailure, Activated, Deactivated, InTrial |
   */
  company_compta_subscription_status?:
    | "Paused"
    | "ToActivate"
    | "PaymentFailure"
    | "Activated"
    | "Deactivated"
    | "InTrial";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Micro-entrepreneur, Micro-entrepreneur-premium, Bank-entreprise-standard, Bank-entreprise-premium, Bank-entreprise-multi-account, Bank-entreprise-ultimate |
   */
  company_compte_pro_plan?:
    | "Micro-entrepreneur"
    | "Micro-entrepreneur-premium"
    | "Bank-entreprise-standard"
    | "Bank-entreprise-premium"
    | "Bank-entreprise-multi-account"
    | "Bank-entreprise-ultimate";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Paused, ToActivate, PaymentFailure, Activated, Deactivated, InTrial |
   */
  company_compte_pro_subscription_status?:
    | "Paused"
    | "ToActivate"
    | "PaymentFailure"
    | "Activated"
    | "Deactivated"
    | "InTrial";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | CompanyInformation, CredentialsCreation, BankAccountsSynchronization, CardDelivery, OnboardingCall, ContractSignature, TaxesCredentials |
   */
  company_current_onboarding_step?:
    | "CompanyInformation"
    | "CredentialsCreation"
    | "BankAccountsSynchronization"
    | "CardDelivery"
    | "OnboardingCall"
    | "ContractSignature"
    | "TaxesCredentials";
  company_first_closing_date?: string;
  company_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SARL, SAS, EI, EURL, SCI, SASU, SELARL, Association, SELAS, Auto-entrepreneur |
   */
  company_legal_status?:
    | "SARL"
    | "SAS"
    | "EI"
    | "EURL"
    | "SCI"
    | "SASU"
    | "SELARL"
    | "Association"
    | "SELAS"
    | "Auto-entrepreneur";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  company_stakeholder_count?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | IS, RF, BNC, BIC |
   */
  company_tax_system?: "IS" | "RF" | "BNC" | "BIC";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  company_users_count?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Mensuel, Trimestriel, Annuel, Exonéré |
   */
  company_vat_plan?: "Mensuel" | "Trimestriel" | "Annuel" | "Exonéré";
  /**
   * Pays d'où est connecté l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ABW, AFG, AGO, AIA, ALA, ALB, AND, ARE, ARG, ARM, ASM, ATA, ATF, ATG, AUS, AUT, AZE, BDI, BEL, BEN, BES, BFA, BGD, BGR, BHR, BHS, BIH, BLM, BLR, BLZ, BMU, BOL, BRA, BRB, BRN, BTN, BVT, BWA, CAF, CAN, CCK, CHE, CHL, CHN, CIV, CMR, COD, COG, COK, COL, COM, CPV, CRI, CUB, CUW, CXR, CYM, CYP, CZE, DEU, DJI, DMA, DNK, DOM, DZA, ECU, EGY, ERI, ESH, ESP, EST, ETH, FIN, FJI, FLK, FRA, FRO, FSM, GAB, GBR, GEO, GGY, GHA, GIB, GIN, GLP, GMB, GNB, GNQ, GRC, GRD, GRL, GTM, GUF, GUM, GUY, HKG, HMD, HND, HRV, HTI, HUN, IDN, IMN, IND, IOT, IRL, IRN, IRQ, ISL, ISR, ITA, JAM, JEY, JOR, JPN, KAZ, KEN, KGZ, KHM, KIR, KNA, KOR, KWT, LAO, LBN, LBR, LBY, LCA, LIE, LKA, LSO, LTU, LUX, LVA, MAC, MAF, MAR, MCO, MDA, MDG, MDV, MEX, MHL, MKD, MLI, MLT, MMR, MNE, MNG, MNP, MOZ, MRT, MSR, MTQ, MUS, MWI, MYS, MYT, NAM, NCL, NER, NFK, NGA, NIC, NIU, NLD, NOR, NPL, NRU, NZL, OMN, PAK, PAN, PCN, PER, PHL, PLW, PNG, POL, PRI, PRK, PRT, PRY, PSE, PYF, QAT, REU, ROU, RUS, RWA, SAU, SDN, SEN, SGP, SGS, SHN, SJM, SLB, SLE, SLV, SMR, SOM, SPM, SRB, SSD, STP, SUR, SVK, SVN, SWE, SWZ, SXM, SYC, SYR, TCA, TCD, TGO, THA, TJK, TKL, TKM, TLS, TON, TTO, TUN, TUR, TUV, TWN, TZA, UGA, UKR, UMI, URY, USA, UZB, VAT, VCT, VEN, VGB, VIR, VNM, VUT, WLF, WSM, YEM, ZAF, ZMB, ZWE |
   */
  location?:
    | "ABW"
    | "AFG"
    | "AGO"
    | "AIA"
    | "ALA"
    | "ALB"
    | "AND"
    | "ARE"
    | "ARG"
    | "ARM"
    | "ASM"
    | "ATA"
    | "ATF"
    | "ATG"
    | "AUS"
    | "AUT"
    | "AZE"
    | "BDI"
    | "BEL"
    | "BEN"
    | "BES"
    | "BFA"
    | "BGD"
    | "BGR"
    | "BHR"
    | "BHS"
    | "BIH"
    | "BLM"
    | "BLR"
    | "BLZ"
    | "BMU"
    | "BOL"
    | "BRA"
    | "BRB"
    | "BRN"
    | "BTN"
    | "BVT"
    | "BWA"
    | "CAF"
    | "CAN"
    | "CCK"
    | "CHE"
    | "CHL"
    | "CHN"
    | "CIV"
    | "CMR"
    | "COD"
    | "COG"
    | "COK"
    | "COL"
    | "COM"
    | "CPV"
    | "CRI"
    | "CUB"
    | "CUW"
    | "CXR"
    | "CYM"
    | "CYP"
    | "CZE"
    | "DEU"
    | "DJI"
    | "DMA"
    | "DNK"
    | "DOM"
    | "DZA"
    | "ECU"
    | "EGY"
    | "ERI"
    | "ESH"
    | "ESP"
    | "EST"
    | "ETH"
    | "FIN"
    | "FJI"
    | "FLK"
    | "FRA"
    | "FRO"
    | "FSM"
    | "GAB"
    | "GBR"
    | "GEO"
    | "GGY"
    | "GHA"
    | "GIB"
    | "GIN"
    | "GLP"
    | "GMB"
    | "GNB"
    | "GNQ"
    | "GRC"
    | "GRD"
    | "GRL"
    | "GTM"
    | "GUF"
    | "GUM"
    | "GUY"
    | "HKG"
    | "HMD"
    | "HND"
    | "HRV"
    | "HTI"
    | "HUN"
    | "IDN"
    | "IMN"
    | "IND"
    | "IOT"
    | "IRL"
    | "IRN"
    | "IRQ"
    | "ISL"
    | "ISR"
    | "ITA"
    | "JAM"
    | "JEY"
    | "JOR"
    | "JPN"
    | "KAZ"
    | "KEN"
    | "KGZ"
    | "KHM"
    | "KIR"
    | "KNA"
    | "KOR"
    | "KWT"
    | "LAO"
    | "LBN"
    | "LBR"
    | "LBY"
    | "LCA"
    | "LIE"
    | "LKA"
    | "LSO"
    | "LTU"
    | "LUX"
    | "LVA"
    | "MAC"
    | "MAF"
    | "MAR"
    | "MCO"
    | "MDA"
    | "MDG"
    | "MDV"
    | "MEX"
    | "MHL"
    | "MKD"
    | "MLI"
    | "MLT"
    | "MMR"
    | "MNE"
    | "MNG"
    | "MNP"
    | "MOZ"
    | "MRT"
    | "MSR"
    | "MTQ"
    | "MUS"
    | "MWI"
    | "MYS"
    | "MYT"
    | "NAM"
    | "NCL"
    | "NER"
    | "NFK"
    | "NGA"
    | "NIC"
    | "NIU"
    | "NLD"
    | "NOR"
    | "NPL"
    | "NRU"
    | "NZL"
    | "OMN"
    | "PAK"
    | "PAN"
    | "PCN"
    | "PER"
    | "PHL"
    | "PLW"
    | "PNG"
    | "POL"
    | "PRI"
    | "PRK"
    | "PRT"
    | "PRY"
    | "PSE"
    | "PYF"
    | "QAT"
    | "REU"
    | "ROU"
    | "RUS"
    | "RWA"
    | "SAU"
    | "SDN"
    | "SEN"
    | "SGP"
    | "SGS"
    | "SHN"
    | "SJM"
    | "SLB"
    | "SLE"
    | "SLV"
    | "SMR"
    | "SOM"
    | "SPM"
    | "SRB"
    | "SSD"
    | "STP"
    | "SUR"
    | "SVK"
    | "SVN"
    | "SWE"
    | "SWZ"
    | "SXM"
    | "SYC"
    | "SYR"
    | "TCA"
    | "TCD"
    | "TGO"
    | "THA"
    | "TJK"
    | "TKL"
    | "TKM"
    | "TLS"
    | "TON"
    | "TTO"
    | "TUN"
    | "TUR"
    | "TUV"
    | "TWN"
    | "TZA"
    | "UGA"
    | "UKR"
    | "UMI"
    | "URY"
    | "USA"
    | "UZB"
    | "VAT"
    | "VCT"
    | "VEN"
    | "VGB"
    | "VIR"
    | "VNM"
    | "VUT"
    | "WLF"
    | "WSM"
    | "YEM"
    | "ZAF"
    | "ZMB"
    | "ZWE";
  /**
   * Date de création des identifiants de l'utilisateur
   */
  registration_date?: string;
  /**
   * Flow d'onboarding que l'utilisateur à suivi
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | CreaPlusCompta, ComptaStandalone, DepCap, DepCapPlusCompta, AEComptePro, OnboardingEspaceClient, UpsellBankMenu, UpsellBankDashboard, UpsellBankOnboarding, PostKbis, Other, QontoStandalone |
   */
  registration_flow?:
    | "CreaPlusCompta"
    | "ComptaStandalone"
    | "DepCap"
    | "DepCapPlusCompta"
    | "AEComptePro"
    | "OnboardingEspaceClient"
    | "UpsellBankMenu"
    | "UpsellBankDashboard"
    | "UpsellBankOnboarding"
    | "PostKbis"
    | "Other"
    | "QontoStandalone";
}

export interface ArticleOpenedProperties {
  /**
   * Nom de l'article
   */
  Article_name: string;
}

export interface BankBridgeSyncTabOpenedProperties {
  /**
   * API de synchronisation bancaire utilisé
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Bridge, Qonto |
   */
  api_used: "Bridge" | "Qonto";
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Onboarding, Banking Accounts Page |
   */
  source: "Onboarding" | "Banking Accounts Page";
}

export interface BillingLineAddedProperties {
  /**
   * Type de ligne de facturation
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Catalog Item, Unsaved Item |
   */
  line_type: "Catalog Item" | "Unsaved Item";
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Invoice, Quote |
   */
  source: "Invoice" | "Quote";
}

export interface BillingLineDeletedProperties {
  /**
   * Type de ligne de facturation
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Catalog Item, Unsaved Item |
   */
  line_type: "Catalog Item" | "Unsaved Item";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Quote, Invoice |
   */
  source: "Quote" | "Invoice";
}

export interface CatalogItemBrowsedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Catalog Item Page, Invoice, Quote |
   */
  source: "Catalog Item Page" | "Invoice" | "Quote";
}

export interface CategorizationFlowInitiatedProperties {
  /**
   * Nom de la catégorie éxistante
   */
  existing_category: string;
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Sidebar, InlineRow |
   */
  source: "Sidebar" | "InlineRow";
}

export interface ClientsBrowsedProperties {
  /**
   * Page d'origine de l'action
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Clients Page, Invoice, Quote |
   */
  source: "Clients Page" | "Invoice" | "Quote";
}

export interface ImmobilisationCancellationInitiatedProperties {
  /**
   * Statut d'immobilisation de la catégorie; est-ce une catégorie immobilisable ou forcément immobilisée
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Immobilisé, Immobilisable |
   */
  category_immobilisation_status: "Immobilisé" | "Immobilisable";
}

export interface InvoiceUpdatedProperties {
  /**
   * Modifications apportées
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  modified_fields: ("Due Date" | "Title" | "Payment Details" | "Billing Lines" | "Client")[];
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Quote, Draft, Duplicata |
   */
  source: "Quote" | "Draft" | "Duplicata";
}

export interface NewCatalogItemFormOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Catalog Item Page, Invoice, Quote |
   */
  source: "Catalog Item Page" | "Invoice" | "Quote";
}

export interface NewClientFormOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Clients Page, Invoice, Quote |
   */
  source: "Clients Page" | "Invoice" | "Quote";
}

export interface NewInvoiceFormOpenedProperties {
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Quote, Invoice Page, Clients Page, Catalog Page, Duplicata, Draft |
   */
  source: "Quote" | "Invoice Page" | "Clients Page" | "Catalog Page" | "Duplicata" | "Draft";
}

export interface NewQuoteFormOpenedProperties {
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Quote, Clients Page, Catalog Page, Duplicata, Draft |
   */
  source: "Quote" | "Clients Page" | "Catalog Page" | "Duplicata" | "Draft";
}

export interface OnboardingCompanyInfoEnteredProperties {
  activity_start_date: string;
  /**
   * Mois de clôture comptable de l'entreprise
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | January, February, March, April, May, June, July, August, September, October, November, December |
   */
  closing_month:
    | "January"
    | "February"
    | "March"
    | "April"
    | "May"
    | "June"
    | "July"
    | "August"
    | "September"
    | "October"
    | "November"
    | "December";
  /**
   * Première date de clôture de l'exercice
   */
  first_closing_date: string;
  /**
   * Régime fiscal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | IS, RF, BNC, BIC |
   */
  tax_system: "IS" | "RF" | "BNC" | "BIC";
  /**
   * Régime de TVA
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Mensuel, Trimestriel, Annuel, Exonéré |
   */
  vat_plan: "Mensuel" | "Trimestriel" | "Annuel" | "Exonéré";
}

export interface OnboardingCompanyInfoFilledProperties {
  activity_type: string;
  /**
   * **Adresse de l'entreprise**
   */
  Adress: string;
  /**
   * **Ville**
   */
  city: string;
  /**
   * **Capital social**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  company_capital: number;
  /**
   * **Dénomination sociale**
   */
  company_name: string;
  /**
   * **Complété par**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Pappers, Client |
   */
  filled_by: "Pappers" | "Client";
  /**
   * **Forme légale**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Auto-entrepreneur, SAS, SASU, SARL, EURL, SCI, Association |
   */
  legal_status: "Auto-entrepreneur" | "SAS" | "SASU" | "SARL" | "EURL" | "SCI" | "Association";
  /**
   * **Siren**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  siren: number;
  /**
   * **Code postal**
   */
  zip_code: string;
}

export interface OnboardingUserInfoEnteredProperties {
  /**
   * Channel d'acquisition de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Bouche a oreille, Moteurs de recherche (google, bing), Autre site internet, Chaine youtube LegalPlace, Autre chaine youtube, Via un partenaire de LegalPlace, Réseaux sociaux Racem Flazi, Autre |
   */
  acquisition_channel:
    | "Bouche a oreille"
    | "Moteurs de recherche (google"
    | "bing)"
    | "Autre site internet"
    | "Chaine youtube LegalPlace"
    | "Autre chaine youtube"
    | "Via un partenaire de LegalPlace"
    | "Réseaux sociaux Racem Flazi"
    | "Autre";
}

export interface OperationCategorizedProperties {
  /**
   * **Nom de la catégorie finale**
   */
  category: string;
  /**
   * **Le keyword de la recherche**
   */
  keyword: string;
  /**
   * Source de la categorisation initiale de la transaction. Si rempli alors il s'agit de recatégorisation.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | AI, Other, User, AccountingCollaborator, MassByUser, MassByAccountingCollaborator |
   */
  old_categorization_source:
    | "AI"
    | "Other"
    | "User"
    | "AccountingCollaborator"
    | "MassByUser"
    | "MassByAccountingCollaborator";
  /**
   * **Nom de la catégorie parente**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Toutes les catégories, Frais d'activité, Quotidien, Energie, Bureaux & Locaux, Véhicules, Machines & Matériel, Digital, Dirigeants & Associés, Salariés & Stagiaires, Prestataires externes, Banque & Finance, Impôts, Dépense personnelle |
   */
  parent_category:
    | "Toutes les catégories"
    | "Frais d'activité"
    | "Quotidien"
    | "Energie"
    | "Bureaux & Locaux"
    | "Véhicules"
    | "Machines & Matériel"
    | "Digital"
    | "Dirigeants & Associés"
    | "Salariés & Stagiaires"
    | "Prestataires externes"
    | "Banque & Finance"
    | "Impôts"
    | "Dépense personnelle";
}

export interface PageViewedProperties {
  location: string;
  path_name: string;
  platform: string;
  referrer?: string;
}

export interface PappersCompanyInfoEditedProperties {
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Onboarding, New Client |
   */
  source: "Onboarding" | "New Client";
}

export interface PappersCompanyInfoSearchedProperties {
  /**
   * **Résultat de la recherche Pappers**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  result: number;
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Onboarding, New Client |
   */
  source: "Onboarding" | "New Client";
}

export interface PappersCompanyInfoUsedProperties {
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Onboarding, New Client |
   */
  source: "Onboarding" | "New Client";
}

export interface PappersCompanyNotFoundProperties {
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Onboarding, New Client |
   */
  source: "Onboarding" | "New Client";
}

export interface QuoteToInvoiceFlowInitiatedProperties {
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Quote Accepted, Generate Invoice Button |
   */
  source: "Quote Accepted" | "Generate Invoice Button";
}

export interface QuoteUpdatedProperties {
  /**
   * Modifications apportées
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  modified_fields: ("Validity Date" | "Title" | "Additional Information" | "Billing Lines" | "Client")[];
}

export interface SalesChannelCreationSuggestedProperties {
  /**
   * **Nombre de transactions matchées**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  matching_transactions_count: number;
  /**
   * **Nom de la méthode de paiement identifiée**
   */
  suggested_method: string;
}

export interface SignUpEmailSentProperties {
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Crea+compta, Compta Standalone, DepCap, Compta Post Kbis, DepCap+Compta, AE Compte Pro, Qonto Standalone |
   */
  source:
    | "Crea+compta"
    | "Compta Standalone"
    | "DepCap"
    | "Compta Post Kbis"
    | "DepCap+Compta"
    | "AE Compte Pro"
    | "Qonto Standalone";
}

export interface SimilarTransactionsCategorizedProperties {
  /**
   * **Nombre de transactions similaires identifiées**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  categorized_transactions_count: number;
  /**
   * **Nombre de transactions similaires sélectionnées pour catégorisation**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  similar_transactions_count: number;
}

export interface StakeholderListSubmittedProperties {
  /**
   * Interface d'origine de l'action de l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Onboarding |
   */
  source: "Onboarding";
  /**
   * Nombre de dirigeants et associés différents
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  stakeholder_count: number;
}

export interface SupportContactMeetingClickedProperties {
  /**
   * Status du comptable.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Assigned Accountant, No Accountant |
   */
  support_status: "Assigned Accountant" | "No Accountant";
}

export interface SupportIntercomOpenedProperties {
  /**
   * Interface d'ouverture vers le support intercom
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Menu, MonComptable, Bulle |
   */
  source: "Menu" | "MonComptable" | "Bulle";
}

export interface TransactionDetailsOpenedProperties {
  /**
   * Détail de la catégorisation
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Not Categorized, Categorized By AI, Manually categorized |
   */
  categorization_status: "Not Categorized" | "Categorized By AI" | "Manually categorized";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AcademyDismissed implements BaseEvent {
  event_type = 'Academy Dismissed';
}

export class AcademyOpened implements BaseEvent {
  event_type = 'Academy Opened';
}

export class ArticleDatabaseIntercomOpened implements BaseEvent {
  event_type = 'Article Database Intercom Opened';
}

export class ArticleOpened implements BaseEvent {
  event_type = 'Article Opened';

  constructor(
    public event_properties: ArticleOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BankBridgeSyncTabOpened implements BaseEvent {
  event_type = 'Bank Bridge Sync Tab Opened';

  constructor(
    public event_properties: BankBridgeSyncTabOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BillingLineAdded implements BaseEvent {
  event_type = 'Billing Line Added';

  constructor(
    public event_properties: BillingLineAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BillingLineDeleted implements BaseEvent {
  event_type = 'Billing Line Deleted';

  constructor(
    public event_properties: BillingLineDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CatalogItemBrowsed implements BaseEvent {
  event_type = 'Catalog Item Browsed';

  constructor(
    public event_properties: CatalogItemBrowsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CategorizationFlowInitiated implements BaseEvent {
  event_type = 'Categorization Flow Initiated';

  constructor(
    public event_properties: CategorizationFlowInitiatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientsBrowsed implements BaseEvent {
  event_type = 'Clients Browsed';

  constructor(
    public event_properties: ClientsBrowsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompanyNotFoundClicked implements BaseEvent {
  event_type = 'Company Not Found Clicked';
}

export class GetAccountingInDashboardClicked implements BaseEvent {
  event_type = 'Get Accounting In Dashboard Clicked';
}

export class GetAccountingInOnboardingBankViewed implements BaseEvent {
  event_type = 'Get Accounting In Onboarding Bank Viewed';
}

export class GetAccountingPageFromMenuViewed implements BaseEvent {
  event_type = 'Get Accounting Page From Menu Viewed';
}

export class GetBusinessAccountPageFromMenuViewed implements BaseEvent {
  event_type = 'Get Business Account Page From Menu Viewed';
}

export class ImmobilisationCancellationInitiated implements BaseEvent {
  event_type = 'Immobilisation Cancellation Initiated';

  constructor(
    public event_properties: ImmobilisationCancellationInitiatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ImmobilisationMergeInitiated implements BaseEvent {
  event_type = 'Immobilisation Merge Initiated';
}

export class InvoiceUpdated implements BaseEvent {
  event_type = 'Invoice Updated';

  constructor(
    public event_properties: InvoiceUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewCatalogItemFormOpened implements BaseEvent {
  event_type = 'New Catalog Item Form Opened';

  constructor(
    public event_properties: NewCatalogItemFormOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewClientFormOpened implements BaseEvent {
  event_type = 'New Client Form Opened';

  constructor(
    public event_properties: NewClientFormOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewInvoiceFormOpened implements BaseEvent {
  event_type = 'New Invoice Form Opened';

  constructor(
    public event_properties: NewInvoiceFormOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewQuoteFormOpened implements BaseEvent {
  event_type = 'New Quote Form Opened';

  constructor(
    public event_properties: NewQuoteFormOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingCompanyInfoEntered implements BaseEvent {
  event_type = 'Onboarding Company Info Entered';

  constructor(
    public event_properties: OnboardingCompanyInfoEnteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingCompanyInfoFilled implements BaseEvent {
  event_type = 'Onboarding Company Info Filled';

  constructor(
    public event_properties: OnboardingCompanyInfoFilledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingUserInfoEntered implements BaseEvent {
  event_type = 'Onboarding User Info Entered';

  constructor(
    public event_properties: OnboardingUserInfoEnteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperationCategorized implements BaseEvent {
  event_type = 'Operation Categorized';

  constructor(
    public event_properties: OperationCategorizedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PageViewed implements BaseEvent {
  event_type = 'Page Viewed';

  constructor(
    public event_properties: PageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PappersCompanyInfoEdited implements BaseEvent {
  event_type = 'Pappers Company Info Edited';

  constructor(
    public event_properties: PappersCompanyInfoEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PappersCompanyInfoSearched implements BaseEvent {
  event_type = 'Pappers Company Info Searched';

  constructor(
    public event_properties: PappersCompanyInfoSearchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PappersCompanyInfoUsed implements BaseEvent {
  event_type = 'Pappers Company Info Used';

  constructor(
    public event_properties: PappersCompanyInfoUsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PappersCompanyNotFound implements BaseEvent {
  event_type = 'Pappers Company Not Found';

  constructor(
    public event_properties: PappersCompanyNotFoundProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuoteToInvoiceFlowInitiated implements BaseEvent {
  event_type = 'Quote To Invoice Flow Initiated';

  constructor(
    public event_properties: QuoteToInvoiceFlowInitiatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuoteToInvoiceModificationFormOpened implements BaseEvent {
  event_type = 'Quote To Invoice Modification Form Opened';
}

export class QuoteUpdated implements BaseEvent {
  event_type = 'Quote Updated';

  constructor(
    public event_properties: QuoteUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SalesChannelCreationInitiated implements BaseEvent {
  event_type = 'Sales Channel Creation Initiated';
}

export class SalesChannelCreationSuggested implements BaseEvent {
  event_type = 'Sales Channel Creation Suggested';

  constructor(
    public event_properties: SalesChannelCreationSuggestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignUpEmailSent implements BaseEvent {
  event_type = 'Sign Up Email Sent';

  constructor(
    public event_properties: SignUpEmailSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignUpLinkClicked implements BaseEvent {
  event_type = 'Sign Up Link Clicked';
}

export class SimilarTransactionsCategorizationFlowInitiated implements BaseEvent {
  event_type = 'Similar Transactions Categorization Flow Initiated';
}

export class SimilarTransactionsCategorized implements BaseEvent {
  event_type = 'Similar Transactions Categorized';

  constructor(
    public event_properties: SimilarTransactionsCategorizedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StakeholderListSubmitted implements BaseEvent {
  event_type = 'Stakeholder List Submitted';

  constructor(
    public event_properties: StakeholderListSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SupportContactMeetingClicked implements BaseEvent {
  event_type = 'Support Contact Meeting clicked';

  constructor(
    public event_properties: SupportContactMeetingClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SupportIntercomOpened implements BaseEvent {
  event_type = 'Support intercom Opened';

  constructor(
    public event_properties: SupportIntercomOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransactionDetailsOpened implements BaseEvent {
  event_type = 'Transaction Details Opened';

  constructor(
    public event_properties: TransactionDetailsOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Academy Dismissed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Academy%20Dismissed)
   *
   * Le client ne veut plus afficher l’académie
   *
   * @param options Amplitude event options.
   */
  academyDismissed(
    options?: EventOptions,
  ) {
    return this.track(new AcademyDismissed(), options);
  }

  /**
   * Academy Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Academy%20Opened)
   *
   * Le client a ouvert l'académie
   *
   * @param options Amplitude event options.
   */
  academyOpened(
    options?: EventOptions,
  ) {
    return this.track(new AcademyOpened(), options);
  }

  /**
   * Article Database Intercom Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Article%20Database%20Intercom%20Opened)
   *
   * Le client clique vers la liste d'articles intercom
   *
   * @param options Amplitude event options.
   */
  articleDatabaseIntercomOpened(
    options?: EventOptions,
  ) {
    return this.track(new ArticleDatabaseIntercomOpened(), options);
  }

  /**
   * Article Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Article%20Opened)
   *
   * Le client clique sur un article mis en avant
   *
   * @param properties The event's properties (e.g. Article_name)
   * @param options Amplitude event options.
   */
  articleOpened(
    properties: ArticleOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ArticleOpened(properties), options);
  }

  /**
   * Bank Bridge Sync Tab Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Bank%20Bridge%20Sync%20Tab%20Opened)
   *
   * L'utilisateur clic pour synchroniser un compte et est renvoyé vers Bridge ou Qonto
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. api_used)
   * @param options Amplitude event options.
   */
  bankBridgeSyncTabOpened(
    properties: BankBridgeSyncTabOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankBridgeSyncTabOpened(properties), options);
  }

  /**
   * Billing Line Added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Billing%20Line%20Added)
   *
   * Ajout d'une ligne à la facture
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. line_type)
   * @param options Amplitude event options.
   */
  billingLineAdded(
    properties: BillingLineAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillingLineAdded(properties), options);
  }

  /**
   * Billing Line Deleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Billing%20Line%20Deleted)
   *
   * **Suppression d'une ligne à la facture**
   *
   * Owner: Raphaël El Bèze
   *
   * @param properties The event's properties (e.g. line_type)
   * @param options Amplitude event options.
   */
  billingLineDeleted(
    properties: BillingLineDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillingLineDeleted(properties), options);
  }

  /**
   * Catalog Item Browsed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Catalog%20Item%20Browsed)
   *
   * L'utilisateur parcours ses "Prestations"
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  catalogItemBrowsed(
    properties: CatalogItemBrowsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CatalogItemBrowsed(properties), options);
  }

  /**
   * Categorization Flow Initiated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Categorization%20Flow%20Initiated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. existing_category)
   * @param options Amplitude event options.
   */
  categorizationFlowInitiated(
    properties: CategorizationFlowInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CategorizationFlowInitiated(properties), options);
  }

  /**
   * Clients Browsed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Clients%20Browsed)
   *
   * Le user consulte la liste de ses clients
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  clientsBrowsed(
    properties: ClientsBrowsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientsBrowsed(properties), options);
  }

  /**
   * Company Not Found Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Company%20Not%20Found%20Clicked)
   *
   * L'utilisateur clique sur je ne trouve pas mon entreprise
   *
   * @param options Amplitude event options.
   */
  companyNotFoundClicked(
    options?: EventOptions,
  ) {
    return this.track(new CompanyNotFoundClicked(), options);
  }

  /**
   * Get Accounting In Dashboard Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Get%20Accounting%20In%20Dashboard%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  getAccountingInDashboardClicked(
    options?: EventOptions,
  ) {
    return this.track(new GetAccountingInDashboardClicked(), options);
  }

  /**
   * Get Accounting In Onboarding Bank Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Get%20Accounting%20In%20Onboarding%20Bank%20Viewed)
   *
   * **L'utilisateur a cliqué sur l'upsell Compta dans l'onboarding banque**
   *
   * @param options Amplitude event options.
   */
  getAccountingInOnboardingBankViewed(
    options?: EventOptions,
  ) {
    return this.track(new GetAccountingInOnboardingBankViewed(), options);
  }

  /**
   * Get Accounting Page From Menu Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Get%20Accounting%20Page%20From%20Menu%20Viewed)
   *
   * **L'utilisateur a cliqué sur l'upsell Compta dans le menu de navigation**
   *
   * @param options Amplitude event options.
   */
  getAccountingPageFromMenuViewed(
    options?: EventOptions,
  ) {
    return this.track(new GetAccountingPageFromMenuViewed(), options);
  }

  /**
   * Get Business Account Page From Menu Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Get%20Business%20Account%20Page%20From%20Menu%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  getBusinessAccountPageFromMenuViewed(
    options?: EventOptions,
  ) {
    return this.track(new GetBusinessAccountPageFromMenuViewed(), options);
  }

  /**
   * Immobilisation Cancellation Initiated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Immobilisation%20Cancellation%20Initiated)
   *
   * L'utilisateur a démarré le flux d'annulation/suppression d'une immobilisation
   *
   * @param properties The event's properties (e.g. category_immobilisation_status)
   * @param options Amplitude event options.
   */
  immobilisationCancellationInitiated(
    properties: ImmobilisationCancellationInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImmobilisationCancellationInitiated(properties), options);
  }

  /**
   * Immobilisation Merge Initiated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Immobilisation%20Merge%20Initiated)
   *
   * L'utilisateur a démarré le flux d'association de plusieurs immobilisations
   *
   * @param options Amplitude event options.
   */
  immobilisationMergeInitiated(
    options?: EventOptions,
  ) {
    return this.track(new ImmobilisationMergeInitiated(), options);
  }

  /**
   * Invoice Updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Invoice%20Updated)
   *
   * Facture modifiée
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. modified_fields)
   * @param options Amplitude event options.
   */
  invoiceUpdated(
    properties: InvoiceUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvoiceUpdated(properties), options);
  }

  /**
   * New Catalog Item Form Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/New%20Catalog%20Item%20Form%20Opened)
   *
   * Le formulaire de création d 'une Prestation s'est ouvert
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  newCatalogItemFormOpened(
    properties: NewCatalogItemFormOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewCatalogItemFormOpened(properties), options);
  }

  /**
   * New Client Form Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/New%20Client%20Form%20Opened)
   *
   * **Le formulaire de création d'un client s'ouvre**
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  newClientFormOpened(
    properties: NewClientFormOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewClientFormOpened(properties), options);
  }

  /**
   * New Invoice Form Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/New%20Invoice%20Form%20Opened)
   *
   * Ouverture de l'interface de création de facture
   *
   * Owner: Raphaël El Bèze
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  newInvoiceFormOpened(
    properties: NewInvoiceFormOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewInvoiceFormOpened(properties), options);
  }

  /**
   * New Quote Form Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/New%20Quote%20Form%20Opened)
   *
   * Ouverture de l'interface de création de devis
   *
   * Owner: Raphaël El Bèze
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  newQuoteFormOpened(
    properties: NewQuoteFormOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NewQuoteFormOpened(properties), options);
  }

  /**
   * Onboarding Company Info Entered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Onboarding%20Company%20Info%20Entered)
   *
   * Informations entreprise entrées
   *
   * @param properties The event's properties (e.g. activity_start_date)
   * @param options Amplitude event options.
   */
  onboardingCompanyInfoEntered(
    properties: OnboardingCompanyInfoEnteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingCompanyInfoEntered(properties), options);
  }

  /**
   * Onboarding Company Info Filled
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Onboarding%20Company%20Info%20Filled)
   *
   * **Informations entreprise entrées**
   *
   * @param properties The event's properties (e.g. activity_type)
   * @param options Amplitude event options.
   */
  onboardingCompanyInfoFilled(
    properties: OnboardingCompanyInfoFilledProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingCompanyInfoFilled(properties), options);
  }

  /**
   * Onboarding User Info Entered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Onboarding%20User%20Info%20Entered)
   *
   * **L'utilisateur à entré ses informations personnelles dans l'onboarding**
   *
   * @param properties The event's properties (e.g. acquisition_channel)
   * @param options Amplitude event options.
   */
  onboardingUserInfoEntered(
    properties: OnboardingUserInfoEnteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingUserInfoEntered(properties), options);
  }

  /**
   * Operation Categorized
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Operation%20Categorized)
   *
   * Catégorisation de l'opération terminée
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  operationCategorized(
    properties: OperationCategorizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperationCategorized(properties), options);
  }

  /**
   * Page Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Page%20Viewed)
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  pageViewed(
    properties: PageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageViewed(properties), options);
  }

  /**
   * Pappers Company Info Edited
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Pappers%20Company%20Info%20Edited)
   *
   * Informations entreprise issue de Pappers modifiées
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  pappersCompanyInfoEdited(
    properties: PappersCompanyInfoEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PappersCompanyInfoEdited(properties), options);
  }

  /**
   * Pappers Company Info Searched
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Pappers%20Company%20Info%20Searched)
   *
   * Entreprise recherchée sur Pappers
   *
   * @param properties The event's properties (e.g. result)
   * @param options Amplitude event options.
   */
  pappersCompanyInfoSearched(
    properties: PappersCompanyInfoSearchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PappersCompanyInfoSearched(properties), options);
  }

  /**
   * Pappers Company Info Used
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Pappers%20Company%20Info%20Used)
   *
   * Informations entreprise issue de la recherche Pappers utilisées
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  pappersCompanyInfoUsed(
    properties: PappersCompanyInfoUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PappersCompanyInfoUsed(properties), options);
  }

  /**
   * Pappers Company Not Found
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Pappers%20Company%20Not%20Found)
   *
   * **L'utilisateur ne trouve pas son entreprise sur pappers**
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  pappersCompanyNotFound(
    properties: PappersCompanyNotFoundProperties,
    options?: EventOptions,
  ) {
    return this.track(new PappersCompanyNotFound(properties), options);
  }

  /**
   * Quote To Invoice Flow Initiated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Quote%20To%20Invoice%20Flow%20Initiated)
   *
   * Début du flow de transformation d'un devis en facture
   *
   * Owner: Raphaël El Bèze
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  quoteToInvoiceFlowInitiated(
    properties: QuoteToInvoiceFlowInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuoteToInvoiceFlowInitiated(properties), options);
  }

  /**
   * Quote To Invoice Modification Form Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Quote%20To%20Invoice%20Modification%20Form%20Opened)
   *
   * La page d'édition de facture s'ouvre. Dans le flow de transformation d'un Devis en Facture.
   *
   * Owner: Raphaël El Bèze
   *
   * @param options Amplitude event options.
   */
  quoteToInvoiceModificationFormOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuoteToInvoiceModificationFormOpened(), options);
  }

  /**
   * Quote Updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Quote%20Updated)
   *
   * Devis modifié
   *
   * Owner: Raphaël El Bèze
   *
   * @param properties The event's properties (e.g. modified_fields)
   * @param options Amplitude event options.
   */
  quoteUpdated(
    properties: QuoteUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuoteUpdated(properties), options);
  }

  /**
   * Sales Channel Creation Initiated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Sales%20Channel%20Creation%20Initiated)
   *
   * Owner: Yoann Deshaies
   *
   * @param options Amplitude event options.
   */
  salesChannelCreationInitiated(
    options?: EventOptions,
  ) {
    return this.track(new SalesChannelCreationInitiated(), options);
  }

  /**
   * Sales Channel Creation Suggested
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Sales%20Channel%20Creation%20Suggested)
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. matching_transactions_count)
   * @param options Amplitude event options.
   */
  salesChannelCreationSuggested(
    properties: SalesChannelCreationSuggestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SalesChannelCreationSuggested(properties), options);
  }

  /**
   * Sign Up Email Sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Sign%20Up%20Email%20Sent)
   *
   * Email de création d'espace LPPro envoyé
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  signUpEmailSent(
    properties: SignUpEmailSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignUpEmailSent(properties), options);
  }

  /**
   * Sign Up Link Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Sign%20Up%20Link%20Clicked)
   *
   * Lien dans le mail de création d'espace LP Pro cliqué
   *
   * Owner: Yoann Deshaies
   *
   * @param options Amplitude event options.
   */
  signUpLinkClicked(
    options?: EventOptions,
  ) {
    return this.track(new SignUpLinkClicked(), options);
  }

  /**
   * Similar Transactions Categorization Flow Initiated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Similar%20Transactions%20Categorization%20Flow%20Initiated)
   *
   * **Ouverture de la modale de catégorisation des transactions similaires**
   *
   * @param options Amplitude event options.
   */
  similarTransactionsCategorizationFlowInitiated(
    options?: EventOptions,
  ) {
    return this.track(new SimilarTransactionsCategorizationFlowInitiated(), options);
  }

  /**
   * Similar Transactions Categorized
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Similar%20Transactions%20Categorized)
   *
   * Validation de la catégorisation des transactions similaires
   *
   * @param properties The event's properties (e.g. categorized_transactions_count)
   * @param options Amplitude event options.
   */
  similarTransactionsCategorized(
    properties: SimilarTransactionsCategorizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SimilarTransactionsCategorized(properties), options);
  }

  /**
   * Stakeholder List Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Stakeholder%20List%20Submitted)
   *
   * Liste des associés et dirigeants entrée
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  stakeholderListSubmitted(
    properties: StakeholderListSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StakeholderListSubmitted(properties), options);
  }

  /**
   * Support Contact Meeting clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Support%20Contact%20Meeting%20clicked)
   *
   * Le client à cliquer sur prise de RDV depuis la page mon comptable
   *
   * @param properties The event's properties (e.g. support_status)
   * @param options Amplitude event options.
   */
  supportContactMeetingClicked(
    properties: SupportContactMeetingClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SupportContactMeetingClicked(properties), options);
  }

  /**
   * Support intercom Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Support%20intercom%20Opened)
   *
   * Le client ouvre le support intercom
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  supportIntercomOpened(
    properties: SupportIntercomOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SupportIntercomOpened(properties), options);
  }

  /**
   * Transaction Details Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/legalplace/LegalPlacePro%20Staging/events/main/latest/Transaction%20Details%20Opened)
   *
   * Ouverture des détails de la transaction par l'utilisateur
   *
   * Owner: Yoann Deshaies
   *
   * @param properties The event's properties (e.g. categorization_status)
   * @param options Amplitude event options.
   */
  transactionDetailsOpened(
    properties: TransactionDetailsOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionDetailsOpened(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
