import { UpsertSalesReportSteps } from '../../constants/salesReport/modalSteps.constants';

export const salesReport = {
  upsert_modal: {
    modal_title: 'Compléter ma déclaration',
    back_button_label: 'Retour',
    next_step_button_label: 'Continuer',
    cancel_button_label: 'Annuler',
    save_button_label: 'Valider ma déclaration',
    success_message: 'Votre déclaration a bien été enregistrée.',
    error_message: 'Une erreur est survenue.',
    steps: {
      [UpsertSalesReportSteps.MY_SALES]: '1. Mes ventes',
      [UpsertSalesReportSteps.MY_PAYMENT_METHODS]:
        "2. Mes moyens d'encaissement",
      [UpsertSalesReportSteps.MY_FEES]: '3. Mes commissions',
      [UpsertSalesReportSteps.MY_PROOFS]: '4. Mes justificatifs',
    },
    sales_tab: {
      headers: {
        salesType: 'Type de vente',
        country: 'Pays',
        stockGeography: 'Expédié depuis',
        amount: 'Montant TTC',
      },
      inputs: {
        sales_type_dropdown_placeholder: 'Choisir le type de vente',
        country_dropdown_placeholder: 'Choisir le pays',
        stockGeography_dropdown_placeholder: 'Choisir la zone',
        save_amount: 'Valider',
      },
    },
    payment_methods_tab: {},
    fees_tab: {},
    proofs_tab: {},
  },
  page_title: 'Déclaration des ventes',
  banner: {
    title: {
      completed: 'Toutes vos déclarations de ventes sont à jour',
      not_completed:
        '{{ remainingReportsToComplete }} déclarations de ventes à compléter',
    },
    description: {
      completed:
        'Déclarez vos ventes chaque mois pour une TVA toujours exacte.',
      not_completed:
        'Votre balance de TVA est actuellement approximative. Complétez toutes vos déclarations de vente pour voir votre balance de TVA exacte.',
    },
    more_button: 'En savoir plus',
  },
  month_row: {
    badge_labels: {
      generated_draft: 'A compléter',
      completed_by_client: 'Complété',
      missing_proof: 'Justificatif manquant',
      closure_preparation: 'Préparation du Bilan',
      period_closed: 'Exercice clôturé',
    },
    amount_labels: {
      completed_report: {
        totalAmount: 'CA TTC déclaré',
        turnover: 'CA HT déclaré',
        vat: 'TVA collectée',
      },
      not_completed_report: {
        totalAmount: 'Montant encaissé',
        turnover: 'CA HT projeté',
        vat: 'TVA projetée',
      },
      commission: 'Commission',
    },
    amount_helpers: {
      completed_report: {
        totalAmount: '???',
        turnover: '???',
        vat: '???',
      },
      not_completed_report: {
        totalAmount: '???',
        turnover: '???',
        vat: '???',
      },
    },
    button_labels: {
      no_sales: 'Aucune vente à déclarer',
      create_report: 'Compléter ma déclaration',
      update_report: 'Modifier ma déclaration',
      add_proof: 'Ajouter un justificatif',
    },
  },
};
