import { billing } from './billing/index';
import { companyAccountingOnboarding } from './companyAccountingOnboarding/index';
import { onboardingV2 } from './onboardingV2/index';
import academy from './academy';
import { accounting } from './accounting';
import { accountingCollaboratorLogin } from './accountingCollaboratorLogin';
import { accountingCollaboratorResetPassword } from './accountingCollaboratorResetPassword';
import { accountingOverlay } from './accountingOverlay';
import accounting_upsell from './accountingUpsell';
import { accounts } from './accounts';
import { accountSuspendedBanner } from './accountSuspendedBanner';
import { addAccount } from './addAccount';
import { addAccountingSubscription } from './addAccountingSubscription';
import bank_upsell from './bankUpsell';
import { banner } from './banner';
import { cards } from './cards';
import { closeAccount } from './closeAccount';
import { consent } from './consent';
import { creditTransfer } from './creditTransfer';
import { dashboard } from './dashboard';
import { deactivated } from './deactivated';
import { directDebit } from './directDebit';
import { expenses } from './expenses';
import { forgotPassword } from './forgotPassword';
import { general } from './general';
import { immobilisations } from './immobilisations';
import invalidateRequest from './invalidateRequest';
import lockedAccount from './lockedAccount';
import { login } from './login';
import { mandateCreation } from './mandateCreation';
import { menu } from './menu';
import mfa from './mfa';
import { migrationInformation } from './migrationInformation';
import { mobile } from './mobile';
import { myAccountant } from './myAccountant';
import { noConnectionBanner } from './noConnectionBanner';
import { onboarding } from './onboarding';
import { partnerCurrentAccount } from './partnerCurrentAccount';
import { partnerCurrentAccounts } from './partnerCurrentAccounts';
import { paymentMethod } from './paymentMethod';
import { qontoCallback } from './qontoCallback';
import { resetPassword } from './resetPassword';
import { salesReport } from './salesReport';
import { selectSychronizedAccountsModal } from './selectSynchronizedAccountsModal';
import { settings } from './settings';
import { transactions } from './transactions';
import { unlockableContentBanner } from './unlockableContentBanner';
import { updateTaxCredentialsBanner } from './updateTaxCredentialsStatusBanner';

export const frenchTranslations = {
  general,
  login,
  credit_transfer: creditTransfer,
  ...billing,
  direct_debit: directDebit,
  consent,
  transactions,
  cards,
  settings,
  forgot_password: forgotPassword,
  reset_password: resetPassword,
  accounting,
  mandate_creation: mandateCreation,
  expenses,
  partner_current_accounts: partnerCurrentAccounts,
  partner_current_account: partnerCurrentAccount,
  accountingOverlay,
  accounts,
  add_account: addAccount,
  accounting_collaborator_login: accountingCollaboratorLogin,
  accounting_collaborator_reset_password: accountingCollaboratorResetPassword,
  close_account: closeAccount,
  mobile,
  migrationInformation,
  onboarding,
  deactivated,
  menu,
  payment_method: paymentMethod,
  dashboard,
  select_synchronized_accounts_modal: selectSychronizedAccountsModal,
  banner,
  unlockable_content_banner: unlockableContentBanner,
  accounting_upsell,
  bank_upsell,
  onboarding_v2: onboardingV2,
  account_suspended_banner: accountSuspendedBanner,
  add_accounting_subscription: addAccountingSubscription,
  company_accounting_onboarding: companyAccountingOnboarding,
  academy,
  my_accountant: myAccountant,
  immobilisations,
  update_tax_credentials_banner: updateTaxCredentialsBanner,
  qonto_callback: qontoCallback,
  no_connection_banner: noConnectionBanner,
  mfa,
  locked_account: lockedAccount,
  invalidate_request: invalidateRequest,
  sales_report: salesReport,
};
