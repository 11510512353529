import type { AxiosResponse } from 'axios';

import type { SalesReportsHistoryResponse } from '@legalplace/bankroot-api/modules/salesReport/dto';

import { api } from './api';

export const getSalesMonthReportsRequest = (
  companyId: string,
  { startDate, endDate }: { startDate: string; endDate: string }
): Promise<AxiosResponse<SalesReportsHistoryResponse[]>> =>
  api.get(
    `company/${companyId}/sales-report?startDate=${startDate}&endDate=${endDate}`
  );
