import type { AxiosResponse } from 'axios';

import type { IBillingDocumentFilters } from '@legalplace/bankroot-api/interfaces';
import type {
  CreateInvoiceRequestDto,
  GetBillingInvoiceResponseDto,
  GetInvoiceFileDownloadUrlsResponseDto,
  UpdateInvoiceRequestDto,
} from '@legalplace/bankroot-api/modules/billing/dto';
import type { PageDto } from '@legalplace/bankroot-api/modules/pagination/dto';
import type { BillingInvoiceStatus } from '@legalplace/shared';

import type { PaginationOptions } from '../../../store/transactions/types';
import { getDocumentRequestParams } from '../../billing/requestParams';
import { api } from '../api';

export const getInvoicesRequest = (
  companyId: string,
  filters?: IBillingDocumentFilters,
  paginationOptions?: PaginationOptions
): Promise<AxiosResponse<PageDto<GetBillingInvoiceResponseDto>>> =>
  api.get(
    `/billing/invoice?${getDocumentRequestParams(
      companyId,
      filters,
      paginationOptions
    )}`
  );

export const getInvoiceRequest = (
  invoiceId: string
): Promise<AxiosResponse<GetBillingInvoiceResponseDto>> =>
  api.get(`/billing/invoice/${invoiceId}`);

export const createInvoiceRequest = (
  createBillingInvoice: CreateInvoiceRequestDto
): Promise<AxiosResponse<GetBillingInvoiceResponseDto>> =>
  api.post(`/billing/invoice/`, createBillingInvoice);

export const updateInvoiceRequest = (
  invoiceId: string,
  updateBillingInvoice: UpdateInvoiceRequestDto
): Promise<AxiosResponse<GetBillingInvoiceResponseDto>> =>
  api.patch(`/billing/invoice/${invoiceId}`, updateBillingInvoice);

export const getBillingInvoiceFileDownloadUrls = (
  invoiceId: string
): Promise<AxiosResponse<GetInvoiceFileDownloadUrlsResponseDto>> =>
  api.get(`/billing/invoice/${invoiceId}/files`);

export const deleteDraftInvoiceRequest = (
  invoiceId: string
): Promise<AxiosResponse<void>> => api.delete(`/billing/invoice/${invoiceId}`);

export const generateInvoiceFromQuoteRequest = (
  quoteId: string,
  billingInvoiceStatus: BillingInvoiceStatus
): Promise<AxiosResponse<GetBillingInvoiceResponseDto>> =>
  api.post(`/billing/invoice/generate-from-quote`, {
    quoteId,
    billingInvoiceStatus,
  });
