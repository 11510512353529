import { BillingDocumentType } from '@legalplace/documents/index';
import { BillingInvoiceStatus } from '@legalplace/shared';

export const billingInvoice = {
  title_page: 'Factures',
  invoice_stats: {
    amount_paid_label: 'Payées',
    amount_to_collect_label: 'À encaisser',
    amount_late_for_collection_label: 'Dont en retard',
  },
  duplicating_invoice: 'Duplication en cours',
  copy_of: 'Copie de {{invoiceTitle}}',
  invoice_dashboard: {
    title: 'Filtres {{currentActiveFilterCount}}',
    footer_text_zero: '0 facture',
    footer_text_one: '1 facture',
    footer_text_other: '{{count}} factures',
    invoices_dashboard_filter_result_text: '{{invoicesCount}} factures',
    searchbar_placeholder: 'Nom, client, montant...',
    reset_button: 'Réinitialiser',
    invoices_search_button: 'Recherche',
    no_item: 'Aucune facture effectuée pour le moment',
    no_item_subtitle: "Toutes vos factures s'afficheront ici.",
    no_filtered_item: 'Aucun résultat avec les critères sélectionnés',
    no_filtered_item_subtitle: 'Essayez de réajuster votre recherche.',
    transaction_dashboard_Draft: 'Brouillon',
    transaction_dashboard_Paid: 'Payée',
    transaction_dashboard_Unpaid: 'À encaisser',
    transaction_dashboard_Late: 'En retard',
    transaction_dashboard_Cancelled: 'Annulée',
    accounts_filter_button: 'Comptes',
    accounts_filter_action_button: 'Appliquer',
    accounts_reset_action_button: 'Réinitialiser',
    bill_status: {
      Draft: 'Brouillon',
      Paid: 'Payée',
      Unpaid: 'À encaisser',
      Late: 'En retard',
      Cancelled: 'Annulée',
    },
    Draft: 'Brouillon',
    Paid: 'Payée',
    Unpaid: 'À encaisser',
    Late: 'En retard',
    Cancelled: 'Annulée',
    no_filtered_invoice: 'Aucun résultat avec les critères sélectionnés',
    no_invoice_subtitle: 'Essayez de réajuster votre recherche.',
    no_invoice: 'Aucune facture effectuée pour le moment.',
    date_filter_button: 'Date',
    bill_status_filter_button: 'Statut',
    bill_status_reset_action_button: 'Réinitialiser',
    bill_status_filter_action_button: 'Appliquer',
    date_from: 'Date de début',
    date_to: 'Date de fin',
    date_reset_action_button: 'Réinitialiser',
    date_filter_action_button: 'Appliquer',
    date_filter_title: 'Date',
    billingInvoiceStatuses_filter_title: 'Statut',
    apply_filters_button: 'Appliquer',
    reset_all_button: 'Réinitialiser',
    mobile_filter_button: 'Filtrer',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    no_invoice_file_to_download:
      "La facture n'est pas encore disponible, réessayez dans quelques secondes.",
    today_date_row: "Aujourd'hui",
    yesterday_date_row: 'Hier',
  },
  start_billing_document_button: 'Nouvelle facture',
  sidebar: {
    header: {
      document_name: 'Facture',
      [`status_label_${BillingInvoiceStatus.Paid}`]: 'Payée',
      [`status_label_${BillingInvoiceStatus.Cancelled}`]: 'Annulée',
      [`status_label_${BillingInvoiceStatus.Late}`]: 'En retard',
      [`status_label_${BillingInvoiceStatus.Unpaid}`]: 'À encaisser',
      [`status_label_${BillingInvoiceStatus.Draft}`]: 'Brouillon',
      issue_date: "Date d'émission : {{issueDate}}",
      due_date: "Date d'échéance : {{dueDate}}",
      amount_tax_value: 'TTC',
      created_from: 'créée depuis le',
      quote: 'devis {{ billingQuoteNumber }}',
    },
    actions: {
      mark_as_paid_button: 'Marquer comme payée',
      mark_as_unpaid_button: 'Marquer comme impayée',
      duplicate_invoice_button: 'Dupliquer la facture',
      cancel_invoice_button: 'Annuler la facture',
      cancel_invoice_success: 'Facture annulée\u00A0!',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      modal_title: 'Annuler la facture',
      cancel_subtitle: 'Annuler la facture n°{{billNumber}}',
      cancel_warning_message:
        'Si vous annulez cette facture, un avoir du même montant va être créé. Cette action est irréversible, souhaitez-vous continuer ?',
      approve_cancelling_button: 'Oui, annuler et créer un avoir',
      cancel_cancelling_button: 'Annuler',
    },
    files: {
      download_started: 'Téléchargement démarré !',
      modal_title: 'Facture - {{fileName}}',
      download_button: 'Télécharger',
      invoice_generation_error: 'Facture en cours de génération...',
      credit_note_generation_error: 'Avoir en cours de génération...',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      no_invoice_file_to_download:
        "La facture n'est pas encore disponible, réessayez dans quelques secondes.",
    },
  },
  modal: {
    title: 'Nouvelle facture',
    parameter_button: 'Paramètres',
    close_button: 'Fermer',
    details_tab_title: 'Détails',
    entity_search_placeholder: 'Rechercher un client enregistré',
    entity_search_action_button: 'Créer un nouveau client',
    details_title_label: 'Client',
    missing_client_error_message: 'Veuillez sélectionner ou ajouter un client',
    parameters_modal: {
      vat_number_input_label: 'N°TVA Intracommunautaire',
      allowed_types: 'PNG, JPG, JPEG',
      upload_file: 'Ajouter votre logo',
      confirm_delete: 'Oui, supprimer',
      cancel_delete: 'Annuler',
      download_started: 'Téléchargement démarré !',
      modal_title: 'Paramètres',
      numbering_title_label: 'Numérotation',
      numbering_invoice_subtitle_label: 'Facture',
      numbering_quote_subtitle_label: 'Devis',
      invoice_serial_input_label: 'Préfixe de la facture',
      next_invoice_number_input_label: 'Prochain numéro de facture',
      quote_serial_input_label: 'Préfixe du devis',
      next_quote_number_input_label: 'Prochain numéro de devis',
      contact_details_title_label: 'Votre entreprise',
      email_input_label: 'E-mail',
      phone_number_input_label: 'Numéro de téléphone',
      client_email_form: 'E-mail (optionnel)',
      address_1_label: 'Numéro et nom de rue',
      address_2_label: "Complément d'adresse (optionnel)",
      zip_code_label: 'Code postal',
      city_label: 'Ville',
      country_label: 'Pays',
      save_button: 'Appliquer',
      cancel_button: 'Annuler',
      save_billing_success_text: 'Paramètres enregistrés !',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      next_invoice_number_info_text:
        'Votre prochaine facture portera le numéro {{ nextBillNumber }}.',
      next_quote_number_info_text:
        'Votre prochain devis portera le numéro {{ nextBillNumber }}.',
    },
    document_title: {
      title_label: 'Nom de la facture',
      title_placeholder: 'Indiquer un nom pour votre facture',
    },
    document_details: {
      quote_file_name: 'Devis.pdf',
      due_date_error:
        "La date d'échéance ne peut pas être antérieure à la date d'émission.",
      missing_due_date_error: "Veuillez sélectionner la date d'écheance",
      due_date_label: "Date d'échéance",
      emission_date_label: "Date d'émission",
      command_number_form: 'Numéro du bon de commande (optionnel)',
      conditions_label: 'Conditions',
      details_payment_checkbox:
        'Sauvegarder les détails de paiement pour les factures à venir',
      details_payment_label: 'Détails du paiement',
      command_number_text_area:
        'Conditions de paiement et mentions légales (optionnel)',
      save_as_draft_button_text: 'Enregistrer le brouillon',
      save_invoice_button_text: 'Créer une facture',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      submit_error_message: 'Impossible de créer la facture.',
      submit_billing_client_siren_error:
        "SIREN du client absent, veuillez compléter les informations dans la page 'Clients'",
      submit_billing_client_adress_error:
        "Adresse du client incomplète, veuillez compléter les informations dans la page 'Clients'",
      draft_invoice_saved: 'Facture enregistrée comme brouillon\u00A0!',
      draft_invoice_deleted: 'Brouillon supprimé\u00A0!',
      delete_draft_button_text: 'Supprimer le brouillon',
      create_modal: {
        modal_title: 'Créer la facture',
        confirm_save_message1: 'Cette facture portera le numéro ',
        confirm_save_message2: ', et vous ne pourrez plus la modifier.',
        save_button: 'Créer la facture',
        cancel_button: 'Annuler',
        save_invoice_success: 'Facture créée !',
        unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      },
      download_started: 'Téléchargement démarré !',
      turn_into_quote_button_text: 'Transformer la facture en devis',
      quote_created: 'Devis créé !',
    },
    created_from: 'créée depuis le ',
    quote: 'devis {{ billingQuoteNumber }}',
  },
  pdf: {
    details_payment_label: 'Détails du paiement',
    [`${BillingDocumentType.Invoice}_title`]: 'Facture',
    [`${BillingDocumentType.Quote}_title`]: 'Devis',
    bill_number_label: 'Numéro de facture :',
    emission_date_label: "Date d'émission :",
    due_date_label: "Date d'échéance :",
    purchase_order_label: 'Bon de commande :',
    header_billing_lists: 'Description',
    billing_list_quantity: 'Qté',
    billing_list_unit_price: 'Prix unitaire HT',
    billing_list_tva_percentage: 'TVA %',
    billing_list_tva_euro: 'TVA €',
    billing_list_total: 'TOTAL TTC',
    billing_total_wt: 'TOTAL HT : ',
    billing_total_tva: 'TOTAL TVA : ',
    billing_total: 'TOTAL TTC : ',
    footer_note_capital: 'au capital de',
    footer_note_siren: 'SIREN N°',
    footer_note_vat: 'N°TVA Intracommunautaire',
    footer_note_no_vat_string: 'TVA non applicable, article 293 B du CGI',
    footer_note_0_vat_non_ue_string:
      'Autoliquidation de TVA, article 196 de la Directive 2006/112/CE',
    footer_note_0_vat_ue_goods_string:
      'Exonération de TVA, article 262 ter I du code général des impôts',
    footer_note_0_vat_ue_services_string:
      'Autoliquidation de TVA, article 196 de la Directive 2006/112/CE',
  },
};
